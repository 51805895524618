import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import HomeLayout from "../../../layouts/HomeLayout";
import { colors, styles } from "../../../utils/theme";
import { device } from "../../../utils/breakpoints";
import { STRIPE_PORTAL_LINK } from "../../../constants/StripeConstants";
import { transparentize } from "../../../utils/helperFn";

const Confirmed = () => {
  const { t } = useTranslation();

  return (
    <HomeLayout>
      <div className="section position-relative">
        <Container>
          <div className="row">
            <div className="col-lg-6">
              <img
                src="/assets/images/home/about-section.webp"
                alt="Oostwest"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-5 offset-lg-1 align-self-center">
              <BannerTitle>
                <b>{t("subscribe_success_title")}</b>
              </BannerTitle>
              <BannerDescription>
                {t("subscribe_success_description")}
              </BannerDescription>
              <Link
                href={STRIPE_PORTAL_LINK}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                {t("to_stripe_account_portal")}
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </HomeLayout>
  );
};

const Link = styled.a`
  border-radius: ${styles.btn_border_radius};
  padding: 3px 12px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s all ease;
  display: inline-block;
  text-align: center;
  background-color: ${colors.secondary};
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background-color: ${transparentize(colors.secondary, 0.6)};
    color: #fff;
  }

  @media ${device.lg} {
    font-size: 14px;
  }
`;
const BannerDescription = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media ${device.lg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const BannerTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  margin-top: 1rem;
  color: ${colors.header};
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  @media ${device.lg} {
    font-size: 34px;
  }
`;

export default Confirmed;
