import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ImageTextCards from "../Banners/ImageTextCards";
import {
  BannerDescription,
  BannerImage,
  BannerTitle,
} from "../../styles/sharedStyles";

const CommunityBanner = () => {
  const { t } = useTranslation();

  return (
    <div className="section position-relative">
      <div className="container text-center">
        <BannerImage
          src="assets/images/illustrations/illustratie-13.svg"
          alt="Oostwest"
          className="img-fluid"
        />
        <StyledBannerTitle>{t("home_community_title")}</StyledBannerTitle>
        <StyledBannerDescription>
          {t("home_community_description")}
        </StyledBannerDescription>
        <ImageTextCards className={"mt-3 mb-1"} />
      </div>
    </div>
  );
};

const StyledBannerDescription = styled(BannerDescription)`
  margin-bottom: 2rem;
`;

const StyledBannerTitle = styled(BannerTitle)`
  font-weight: 800;
  margin-top: 0;
`;

export default CommunityBanner;
