import React from "react";
import { Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import styled from "styled-components";

const EllipsisDropdown = (props) => {
  return (
    <Dropdown
      overlay={props.menu}
      placement={props.placement}
      trigger={["click"]}
    >
      <Wrapper className="ellipsis-dropdown d-flex">
        <EllipsisOutlined style={{ fontSize: props.size }} />
      </Wrapper>
    </Dropdown>
  );
};

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 10px;
  transition: all 0.2s ease;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

EllipsisDropdown.defaultProps = {
  trigger: "click",
  placement: "bottomRight",
  menu: <Menu />,
};

export default EllipsisDropdown;
