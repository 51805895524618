import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../utils/theme";
import { profile } from "../urls";
import LinkButton from "./LinkButton";
import { Link } from "react-router-dom";

const ProfileCard = ({ user, toProfile = false }) => {
  const { t } = useTranslation();
  return (
    <>
      <CoverImageContainer
        bgImage={
          !!user.cover ? user.cover : "/assets/images/user/coverband_2.png"
        }
      />
      <ProfileContentContainer>
        <AvatarContainer>
          {toProfile ? (
            <Link to={profile}>
              <img
                src={
                  !!user.avatar
                    ? user.avatar
                    : "/assets/images/user/placeholder.png"
                }
                alt={user.firstName + " " + user.lastName}
              />
            </Link>
          ) : (
            <img
              src={
                !!user.avatar
                  ? user.avatar
                  : "/assets/images/user/placeholder.png"
              }
              alt={user.firstName + " " + user.lastName}
            />
          )}
        </AvatarContainer>
        {!!user && !!user.firstName && !!user.lastName && !!user.profession && (
          <ProfileContent>
            <h4>{user.firstName + " " + user.lastName}</h4>
            <p>{user.profession}</p>
          </ProfileContent>
        )}
        {toProfile && (
          <LinkButton to={profile} small type={"outlined"}>
            {t("to_profile")}
          </LinkButton>
        )}
      </ProfileContentContainer>
    </>
  );
};

const CoverImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: clamp(80px, 20vw, 150px);
  background-image: url(${({ bgImage }) => bgImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-clip: border-box;
`;

const ProfileContentContainer = styled.div`
  position: relative;
  padding: 0 1.5rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const AvatarContainer = styled.div`
  position: relative;
  margin-top: -2rem;
  margin-bottom: 0;
  width: min(50%, 6rem);

  img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 50%;
    border: 4px solid white;
  }
`;

const ProfileContent = styled.div`
  text-align: center;

  h4 {
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    font-weight: 600;
    color: ${colors.primaryLight};
    margin: 0;
  }
`;

export default ProfileCard;
