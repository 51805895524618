import React from "react";
import HomeLayout from "../layouts/HomeLayout";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { device } from "../utils/breakpoints";
import { colors } from "../utils/theme";
import { useTranslation } from "react-i18next";
import HireSpaceModal from "../components/Modal/HireSpaceModal";

const BackstagePodcast = () => {
  const { t } = useTranslation();
  return (
    <HomeLayout>
      <Container>
        <Title>{t("podcast_page_title")}</Title>
        <Description>{t("podcast_page_description")}</Description>
        <div
          style={{
            padding: "56.25% 0 0 0",
            position: "relative",
            marginBottom: "3rem",
          }}
        >
          <iframe
            src="https://vimeo.com/event/2826867/embed"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      </Container>
      <HireSpaceModal />
    </HomeLayout>
  );
};

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  @media ${device.lg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

const Title = styled.h2`
  margin-top: 1rem;
  font-size: 26px;
  font-weight: 700;
  color: ${colors.header};
  margin-bottom: 0.1rem;
  text-align: center;

  @media ${device.lg} {
    font-size: 34px;
  }
`;

export default BackstagePodcast;
