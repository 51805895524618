import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../utils/theme";
import EnterEmailBanner from "../components/Banners/EnterEmailBanner";
import CtaBanner from "../components/Banners/CtaBanner";
import { device } from "../utils/breakpoints";
import StyleElement from "../components/StyleElement";
import AboutSection from "../components/Home/AboutSection";
import HomeLayout from "../layouts/HomeLayout";
import CommunityBanner from "../components/Home/CommunityBanner";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import { useDispatch } from "react-redux";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import StyledButton from "../components/StyledButton";
import LinkButton from "../components/LinkButton";
import { contact } from "../urls";
import ImageAndTextBanner from "../components/Banners/ImageAndTextBanner";

const LandingPage = () => {
  const { t } = useTranslation();
  const headerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let i = 0;
    setInterval(function () {
      const element = headerRef.current;
      if (!!element) {
        switch (i) {
          case 0:
            element.style.backgroundImage =
              'url("assets/images/impression/bassist-oostwest.webp")';
            break;
          case 1:
            element.style.backgroundImage =
              'url("assets/images/impression/raven-on-container.webp")';
            break;
          case 2:
            element.style.backgroundImage =
              'url("assets/images/impression/audience-inside.webp")';
            break;
          case 3:
            element.style.backgroundImage =
              'url("assets/images/impression/light-installation.webp")';
            break;
          case 4:
            element.style.backgroundImage =
              'url("assets/images/crew/ow-crew-1.webp")';
            break;
          case 5:
            element.style.backgroundImage =
              'url("assets/images/crew/ow-crew-4.webp")';
            break;
          default:
            element.style.backgroundImage =
              'url("assets/images/crew/ow-crew-4.webp")';
        }
        i++;
        if (i > 4) i = 0;
      }
    }, 5000);
  }, []);

  return (
    <HomeLayout>
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-06.svg"}
          alt={""}
          left
          bottom={-110}
        />
      </div>

      <HeaderWrapper>
        <div className="banner-image">
          <div className="image-container" ref={headerRef} />
        </div>
        <div className="banner-content">
          <div className="content-container">
            <HeaderTitle>{t("header_title")}</HeaderTitle>
            <HeaderSubtitle>{t("header_subtitle")}</HeaderSubtitle>
            <div className={"d-flex mt-3"}>
              <StyledButton onClick={() => dispatch(toggleHireSpaceModal())}>
                {t("rent_a_space")}
              </StyledButton>
              <LinkButton to={contact} small type={"outlined"}>
                {t("get_in_touch")}
              </LinkButton>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <AboutSection />
      <div className={"position-relative"}>
        <LeavesStyleElement
          src={"assets/images/illustrations/illustratie-18.svg"}
          alt={""}
        />
      </div>

      <ImageAndTextBanner
        src={"assets/images/home/flex-section.webp"}
        alt={""}
        title={t("flex_work_title")}
        description={t("flex_work_description")}
        flip
      />

      <CommunityBanner />

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-09.svg"}
          alt={""}
          bottom={-30}
          left
        />
      </div>

      <EnterEmailBanner />

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-14.svg"}
          alt={""}
          bottom={-83}
          left
        />
      </div>

      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-18.svg"}
          alt={""}
          bottom={-30}
          right={-18}
        />
      </div>
      <CtaBanner light />
      <HireSpaceModal />
    </HomeLayout>
  );
};

const LeavesStyleElement = styled.img`
  position: absolute;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.1;
  width: 600px;
  bottom: -20vh;
  right: -15vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -45vh;
    right: -25vh;
  }
`;

const HeaderSubtitle = styled.h4`
  font-size: 22px;
  font-weight: 300;

  @media ${device.lg} {
    font-size: 24px;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 34px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${colors.header};
  @media ${device.lg} {
    font-size: 38px;
  }
`;

const HeaderWrapper = styled.div`
  height: 100vh;
  display: grid;
  gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 20vh;
  z-index: 9;
  isolation: isolate;

  .banner-content {
    grid-column: 1;
    display: grid;
    place-content: center;

    .content-container {
      max-width: 600px;
      padding-inline: 2rem;
    }
  }

  .banner-image {
    display: flex;
    align-items: center;
    min-height: 35vh;
  }

  .image-container {
    display: grid;
    place-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: 0 20px 40px 4px rgba(0, 0, 0, 0.2);
    transition: background 1.5s ease;

    background-image: url("/assets/images/crew/ow-crew-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media ${device.lg} {
    padding-top: 0;
    grid-template-columns: 1fr 1fr;

    .banner-image {
      padding-left: 4rem;
      grid-column: 2;
      grid-row: 1;
    }

    .image-container {
      height: 80%;
      border-radius: 6px 0 0 6px;
      box-shadow: 0 0 40px 8px rgba(0, 0, 0, 0.2);
    }

    .content-container {
      margin-left: 10vw;
    }
  }
`;

export default LandingPage;
