import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import CreatePost from "../../components/CreatePost";
import { useSelector } from "react-redux";
import { profile } from "../../urls";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Feed from "../../components/Feed";
import styled from "styled-components";
import StyleElement from "../../components/StyleElement";
import { device } from "../../utils/breakpoints";
import ProfileCard from "../../components/ProfileCard";
import {
  DashboardFeedCard,
  DashboardWrapper,
  StyleElementContainer,
} from "../../styles/dashboardStyles";
import EventsAside from "../../components/EventsAside";
import useIsMobile from "../../components/Hooks/useIsMobile";

const Home = () => {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.firebaseReducer.profile);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      userProfile.isLoaded &&
      !!userProfile &&
      (!userProfile.firstName || !userProfile.lastName)
    ) {
      navigate(profile);
    }
  }, [navigate, userProfile]);

  return (
    <DashboardLayout>
      <div className={"position-relative"}>
        <LeavesStyleElement
          src={"assets/images/illustrations/illustratie-18.svg"}
          alt={""}
        />
      </div>

      <StyledDashboardWrapper>
        <DashboardContainer>
          {!isMobile && (
            <DashboardSidebar>
              <StyledDashboardFeedCard>
                <ProfileCard user={userProfile} toProfile />
              </StyledDashboardFeedCard>
              <Parallax speed={-30}>
                <div className={"position-relative"}>
                  <ButterflyStyleElement
                    src={"assets/images/illustrations/illustratie-07.svg"}
                  />
                </div>
              </Parallax>
            </DashboardSidebar>
          )}
          <DashboardMain>
            <CreatePost />
            <Feed user={userProfile} />
            {/*<Load />*/}
          </DashboardMain>
          <DashboardAside>
            <EventsAside />
          </DashboardAside>
        </DashboardContainer>
      </StyledDashboardWrapper>
      <StyleElementContainer>
        <StyleElement
          src={"assets/images/illustrations/illustratie-09.svg"}
          alt={""}
          bottom={-30}
          left
        />
      </StyleElementContainer>
    </DashboardLayout>
  );
};

const StyledDashboardWrapper = styled(DashboardWrapper)`
  @media only screen and ${device.maxSm} {
    margin-top: 0;
    padding-inline: 0;
  }
`;

const DashboardContainer = styled.section`
  display: grid;
  gap: 1.5rem;

  @media only screen and ${device.lg} {
    grid-template-columns: 225px 555px;
  }

  @media only screen and ${device.xl} {
    grid-template-columns: 225px 555px 300px;
  }
`;

const DashboardSidebar = styled.div`
  @media only screen and ${device.xl} {
    display: block;
  }
`;

const StyledDashboardFeedCard = styled(DashboardFeedCard)`
  padding: 0;
  margin: 0;
  overflow: hidden;
`;

const DashboardMain = styled.div`
  z-index: 1;
`;

const DashboardAside = styled.aside`
  display: none;

  @media only screen and ${device.xl} {
    display: block;
  }
`;

const LeavesStyleElement = styled.img`
  position: fixed;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.2;
  width: 600px;
  top: 5vh;
  right: -15vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -50vh;
    right: -20vh;
  }
`;

const ButterflyStyleElement = styled.img`
  position: relative;
  width: 180px;
  top: 40vh;
  opacity: 0.4;

  @media ${device.maxXl} {
    bottom: -20vh;
  }

  @media ${device.maxLg} {
    display: none;
  }
`;

export default Home;
