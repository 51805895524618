import React,{Component} from 'react';

class PopupChat extends Component {
    render() {
        return (
            <div></div>
        );
    }
}

export default PopupChat;
