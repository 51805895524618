import styled from "styled-components";
import { PageWrapper } from "./sharedStyles";
import { colors, styles } from "../utils/theme";
import { device } from "../utils/breakpoints";
import { transparentize } from "../utils/helperFn";

const AppWrapper = styled(PageWrapper)`
  background: linear-gradient(
    180deg,
    rgba(245, 237, 224, 1) 50%,
    rgba(98, 144, 111, 1) 95%
  );
`;

const DashboardWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: clamp(15px, 6vw, 3rem);
  margin-bottom: 4rem;
  margin-inline: auto;
  padding-inline: 1rem;
  width: 100%;

  @media only screen and ${device.sm} {
    max-width: 540px;
  }
  @media only screen and ${device.md} {
    max-width: 720px;
  }
  @media only screen and ${device.lg} {
    max-width: 960px;
  }
  @media only screen and ${device.xl} {
    max-width: 1200px;
  }
  @media only screen and ${device.xxl} {
    max-width: 1600px;
  }
`;

const DashboardSection = styled.section`
  max-width: ${({ narrow }) => (narrow ? "900px" : "1128px")};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
`;

const DashboardCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 0;
  width: 100%;
  min-height: fit-content;
  word-wrap: break-word;
  background-clip: border-box;
  background-color: ${transparentize("#ffffff", 0.6)};
  border-radius: ${styles.card_border_radius};
  border: 1px solid ${colors.bg_green};
  backdrop-filter: blur(6px);
  z-index: 1;
`;

const DashboardFeedCard = styled(DashboardCard)`
  border-inline: none;
  border-block: 1px solid ${colors.bg_green};
  border-radius: 0;
  margin-bottom: 1rem;

  @media only screen and ${device.sm} {
    border-radius: ${styles.card_border_radius};
    border: 1px solid ${colors.bg_green};
  }
`;

const StyleElementContainer = styled.div`
  position: relative;
  display: none;

  @media ${device.lg} {
    display: block;
  }
`;

export {
  AppWrapper,
  DashboardWrapper,
  DashboardSection,
  DashboardCard,
  DashboardFeedCard,
  StyleElementContainer,
};
