import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { device } from "../utils/breakpoints";
import { about, home } from "../urls";
import { colors, styles } from "../utils/theme";
import { transparentize } from "../utils/helperFn";
import HomeFooter from "../components/Home/HomeFooter";
import { Link } from "react-router-dom";

const Links = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <main style={{ flex: 1 }}>
        <Container className={"align-items-center"}>
          <Logo src={"/assets/images/logo/logo-512.png"}></Logo>
          <Title>{t("community_oostwest")}</Title>
          <Description>{t("header_title")}</Description>
          <Link className={"link-button"} to={home}>
            {t("check_our_website")}
          </Link>
          <a
            className={"link-button"}
            href={"https://www.youtube.com/channel/UCd1lJt7zs6kNo4RlB91j8Nw"}
            target={"_blank"}
            rel="noreferrer"
          >
            {t("check_our_youtube")}
          </a>
          <a
            className={"link-button"}
            href={"https://www.tiktok.com/@oostwest__"}
            target={"_blank"}
            rel="noreferrer"
          >
            {t("check_our_tiktok")}
          </a>
          <Link className={"link-button"} to={about}>
            {t("rent_at_oostwest")}
          </Link>
        </Container>
        <div className={"position-relative"}>
          <ButterflyStyleElement
            src={"/assets/images/illustrations/illustratie-07.svg"}
          />
        </div>
      </main>
      <HomeFooter />
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    rgba(245, 237, 224, 1) 20%,
    rgba(98, 144, 111, 1) 100%
  );
  overflow: hidden;
  min-height: 90vh;
  position: relative;

  .link-button {
    width: 100%;
    border-radius: ${styles.btn_border_radius};
    padding: ${({ small }) => (small ? "3px 12px" : "1.2rem")};
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    transition: 0.2s all ease;
    display: inline-block;
    text-align: center;
    background-color: ${colors.secondary};
    color: #fff;
    margin-bottom: 2rem;

    &:hover,
    &:active,
    &:focus {
      background-color: ${transparentize(colors.secondary, 0.6)};
      color: #fff;
    }

    @media ${device.lg} {
      font-size: ${({ small }) => (small ? "14px" : "20px")};
    }
  }
`;

const ButterflyStyleElement = styled.img`
  position: absolute;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.3;
  width: 300px;
  bottom: -25vh;
  left: -13vh;

  @media ${device.lg} {
    width: 700px;
    bottom: -100vh;
    left: -20vh;
  }
`;

const Container = styled.div`
  text-align: center;
  padding: 1rem;

  @media ${device.lg} {
    padding-left: 50vh;
    padding-right: 50vh;
  }
`;

const Logo = styled.img`
  width: 250px;
`;

const Title = styled.h2`
  margin-top: 1rem;
  font-size: 26px;
  font-weight: 700;
  color: ${colors.header};
  margin-bottom: 0.1rem;

  @media ${device.lg} {
    font-size: 34px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media ${device.lg} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export default Links;
