import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./main.scss";
import "./assets/css/landing-page.css";
import "swiper/swiper-bundle.css";
import "./translations/i18n";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Home from "./pages/App/Home";
import Agenda from "./pages/App/Agenda";
import Group from "./pages/Group";
import MembersOverview from "./pages/App/MembersOverview";
import Settings from "./pages/App/Settings";
import Profile from "./pages/App/Profile";
import ContactInfo from "./pages/ContactInfo";
import Password from "./pages/Password";
import Payment from "./pages/Payment";
import Notification from "./pages/Notification";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import NotFound from "./pages/NotFound";
import Chat from "./pages/Chat";
import Events from "./pages/App/Events";
import Videos from "./pages/Videos";
import GroupPage from "./pages/GroupPage";
import UserProfile from "./pages/App/UserProfile";
import AuthorPage from "./pages/AuthorPage";
import Analytics from "./pages/Analytics";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import AddSubscriptionContactDetails from "./pages/AddSubscriptionContactDetails";
import RegisterSubscription from "./pages/Subscription/RegisterSubscription";
import {
  about,
  addSubscriptionContactDetails,
  adminOverview,
  agenda,
  chat,
  confirmSubscription,
  contact,
  dashboardHome,
  events,
  failedSubscription,
  links,
  login,
  podcastBackstage,
  privacy,
  profile,
  registerSubscription,
  settings,
  terms_conditions,
  userProfile,
  usersOverview,
} from "./urls";
import Confirmed from "./pages/Subscription/payment/Confirmed";
import Failed from "./pages/Subscription/payment/Failed";
import firebase from "./services/firebase";
import { actionTypes, createFirestoreInstance } from "redux-firestore";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./redux/reducers/rootReducer";
import { load, save } from "redux-localstorage-simple";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  useFirestoreConnect,
} from "react-redux-firebase";
import PrivateRoute from "./PrivateRoute";
import {
  FIRESTORE_EVENTS_TABLE,
  FIRESTORE_FEED_POSTS_TABLE,
  FIRESTORE_HIRE_REQUESTS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "./constants/FirebaseConstants";
import { closeAllModals } from "./redux/actions/modalActions";
import Contact from "./pages/Contact";
import BackstagePodcast from "./pages/BackstagePodcast";
import ScrollToTop from "./components/ScrollToTop";
import Admin from "./pages/App/Admin";
import { ConfigProvider } from "antd";
import { colors } from "./utils/theme";
import { HIRE_REQUEST_STATUS_REJECTED } from "./constants/HireRequestConstants";
import Links from "./pages/Links";
import locale from "antd/locale/nl_NL";
import "dayjs/locale/nl";
import dayjs from "dayjs";

dayjs.locale("nl");
const Root = () => {
  const email = useSelector((state) => state.firebaseReducer.auth.email);
  const dispatch = useDispatch();
  let queries = [];
  if (!!email)
    queries.push(
      {
        collection: FIRESTORE_EVENTS_TABLE,
        orderBy: ["startDate", "asc"],
      },
      {
        collection: FIRESTORE_FEED_POSTS_TABLE,
        orderBy: ["createdAt", "desc"],
      },
      { collection: FIRESTORE_USERS_TABLE },
      {
        collection: FIRESTORE_HIRE_REQUESTS_TABLE,
        where: ["status", "!=", HIRE_REQUEST_STATUS_REJECTED],
      },
    );
  useFirestoreConnect(queries);

  useEffect(() => {
    // Close all modals
    dispatch(closeAllModals());
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: colors.secondary,
            fontFamily: "",
          },
        }}
      >
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            element={<LandingPage />}
          />
          <Route exact path={about} element={<About />} />
          <Route exact path={links} element={<Links />} />
          <Route exact path={contact} element={<Contact />} />
          <Route exact path={privacy} element={<PrivacyPolicy />} />
          <Route exact path={terms_conditions} element={<TermsConditions />} />
          <Route
            exact
            path={addSubscriptionContactDetails}
            element={<AddSubscriptionContactDetails />}
          />
          <Route exact path={podcastBackstage} element={<BackstagePodcast />} />
          <Route
            exact
            path={registerSubscription}
            element={<RegisterSubscription />}
          />
          <Route exact path={confirmSubscription} element={<Confirmed />} />
          <Route exact path={failedSubscription} element={<Failed />} />

          {/* Private routes*/}
          <Route exact path={dashboardHome} element={<PrivateRoute />}>
            <Route exact path={dashboardHome} element={<Home />} />
            <Route exact path={events} element={<Events />} />
            <Route exact path={agenda} element={<Agenda />} />
            <Route exact path={chat} element={<Chat />} />
            <Route exact path={profile} element={<Profile />} />
            <Route exact path={settings} element={<Settings />} />
            <Route exact path={usersOverview} element={<MembersOverview />} />
            <Route exact path={userProfile} element={<UserProfile />} />
            <Route exact path={adminOverview} element={<Admin />} />
          </Route>

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/defaultgroup`}
            element={<Group />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/defaultvideo`}
            element={<Videos />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/defaultanalytics`}
            element={<Analytics />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contactinformation`}
            element={<ContactInfo />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/password`}
            element={<Password />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/payment`}
            element={<Payment />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/defaultnoti`}
            element={<Notification />}
          />
          <Route exact path={login} element={<Login />} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/forgot`}
            element={<Forgot />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/notfound`}
            element={<NotFound />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/grouppage`}
            element={<GroupPage />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/authorpage`}
            element={<AuthorPage />}
          />
        </Routes>
      </ConfigProvider>
    </BrowserRouter>
  );
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true,
  onAuthStateChanged: (authData, firebase, dispatch) => {
    // Clear orders from redux state on user logout, but preserve products
    if (!authData) {
      dispatch({
        type: actionTypes.CLEAR_DATA,
        // preserve: {
        //   data: [FIRESTORE_PRODUCTS_TABLE, FIRESTORE_FEATURED_PRODUCTS_TABLE],
        //   ordered: [
        //     FIRESTORE_PRODUCTS_TABLE,
        //     FIRESTORE_FEATURED_PRODUCTS_TABLE,
        //   ],
        // },
      });
    }
  },
};

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save())),
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Root />
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById("root"),
);
serviceWorker.register();
