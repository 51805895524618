import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
import DashboardLayout from "../../layouts/DashboardLayout";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { createNewMember, userProfileRaw } from "../../urls";
import {
  DashboardCard,
  DashboardSection,
  DashboardWrapper,
} from "../../styles/dashboardStyles";
import { colors } from "../../utils/theme";
import MembersSearchBar from "../../components/MembersSearchBar";
import Fuse from "fuse.js";
import { device } from "../../utils/breakpoints";
import { Col, Form, Input, Modal, notification, Row } from "antd";
import axios from "axios";
import { getFirebaseIDToken } from "../../functions/Firebase";

const MembersOverview = () => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.firestoreReducer.ordered.users);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const [memberForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [sortedUsers, setSortedUsers] = useState(users);
  const [searchResult, setSearchResult] = useState([]);
  const [addMemberModalOpen, setAddMemberModalOpen] = useState(false);

  useEffect(() => {
    if (searchResult.length === 0) {
      const nextSortedUsers = sortBy(users, [
        (item) => {
          return item.firstName + " " + item.lastName;
        },
      ]);
      setSortedUsers(nextSortedUsers);
    } else {
      const nextSortedUsers = [];
      searchResult.forEach((item) => nextSortedUsers.push(item.item));
      setSortedUsers(nextSortedUsers);
    }
  }, [users, searchResult]);

  const handleAddMember = () => {
    memberForm
      .validateFields()
      .then((values) => {
        // Email to lowercase and trim spaces
        const email = values.email.toLowerCase().trim();
        setLoading(true);
        // Check if user does not already exist
        const userExists = users.find((user) => user.email === email);
        if (userExists) {
          notification.error({
            message: t("notifications.member_already_exists"),
          });
          setLoading(false);
        } else {
          // Send email to API to create user
          getFirebaseIDToken().then((token) => {
            const config = {
              headers: { Authorization: token },
            };
            axios
              .post(createNewMember, { email }, config)
              .then((response) => {
                if (response.status === 200) {
                  notification.success({
                    message: t("notifications.member_added"),
                  });
                  setLoading(false);
                  setAddMemberModalOpen(false);
                  memberForm.resetFields();
                }
              })
              .catch((error) => {
                console.log(error);
                notification.error({
                  message: t("notifications.member_not_added"),
                });
                setLoading(false);
              });
          });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const options = {
      includeScore: true,
      minMatchCharLength: 2,
      keys: ["firstName", "lastName"],
    };
    const fuse = new Fuse(users, options);
    const result = fuse.search(e.target.value);
    const sortedResult = [];
    result.forEach((item) => item.score <= 0.3 && sortedResult.push(item));
    setSearchResult(sortedResult);
  };

  return (
    <DashboardLayout>
      <div className={"position-relative"}>
        <ButterflyStyleElement
          src={"/assets/images/illustrations/illustratie-07.svg"}
          alt={""}
        />
      </div>
      <DashboardWrapper className={"container max-container"}>
        <DashboardSection narrow>
          <MembersSearchBar title={t("members")} onSearch={handleSearch} />
          <MembersGrid>
            {sortedUsers.map((user, index) => {
              const fullName = user.firstName + " " + user.lastName;
              if (!!user.firstName)
                return (
                  <Link key={index} to={userProfileRaw + user.id}>
                    <StyledDashboardCard>
                      <div className="w-100 text-center content">
                        <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                          <img
                            src={
                              !!user.avatar
                                ? user.avatar
                                : `/assets/images/user/placeholder.png`
                            }
                            alt={fullName}
                            className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                          />
                        </figure>
                        <div>
                          <h4>{fullName}</h4>
                          <p>{user.profession}</p>
                        </div>
                      </div>
                    </StyledDashboardCard>
                  </Link>
                );
              else return null;
            })}
            {profile.role === "admin" && (
              <StyledDashboardCard onClick={() => setAddMemberModalOpen(true)}>
                <div className="w-100 text-center content">
                  <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                    <img
                      src={`/assets/images/user/placeholder.png`}
                      alt={"Lid toevoegen"}
                      className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                    />
                  </figure>
                  <div>
                    <h4>{t("add_member")}</h4>
                  </div>
                </div>
              </StyledDashboardCard>
            )}
          </MembersGrid>
        </DashboardSection>
      </DashboardWrapper>
      <ToolsStyleElement
        src={"/assets/images/illustrations/illustratie-04.svg"}
        alt={""}
      />
      <Modal
        title={t("add_member")}
        open={addMemberModalOpen}
        onCancel={() => {
          setAddMemberModalOpen(false);
          memberForm.resetFields();
        }}
        onOk={handleAddMember}
        okText={t("save")}
        cancelText={t("close")}
        confirmLoading={loading}
      >
        <Form
          layout="vertical"
          form={memberForm}
          name="advanced_search"
          className="ant-advanced-search-form"
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_email"),
                  },
                  {
                    type: "email",
                    message: t("form.enter_valid_email"),
                  },
                ]}
              >
                <Input
                  placeholder={t("email")}
                  onPressEnter={handleAddMember}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </DashboardLayout>
  );
};

const MembersGrid = styled.div`
  z-index: 1;
  display: grid;
  gap: 1rem;
  margin-bottom: 4rem;
  grid-template-columns: 1fr 1fr;

  @media only screen and ${device.md} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and ${device.lg} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const StyledDashboardCard = styled(DashboardCard)`
  cursor: pointer;
  transition: all 0.3s ease-out;
  height: 100%;

  &:hover {
    background-color: white;
    border-color: ${colors.secondary};
  }

  div.content {
    display: grid;
    gap: 0.5rem;
  }

  h4 {
    font-weight: 700;
    margin-bottom: 0;
  }

  p {
    font-weight: 600;
    color: ${colors.primaryLight};
    margin: 0;
  }
`;

const ButterflyStyleElement = styled.img`
  position: fixed;
  z-index: 0;
  max-height: 70vh;
  max-width: 70vw;
  opacity: 0.3;
  width: 300px;
  bottom: -92vh;
  left: -10vh;

  @media ${device.lg} {
    width: 700px;
    bottom: 25vh;
    left: -10vw;
  }
`;

const ToolsStyleElement = styled.img`
  position: fixed;
  z-index: 0;
  opacity: 0.05;
  width: 600px;
  bottom: -10vh;
  right: -5vw;

  @media ${device.sm} {
    bottom: -10vh;
    right: -15vw;
  }

  @media ${device.lg} {
    width: 700px;
  }
`;

export default MembersOverview;
