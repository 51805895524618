import React from "react";
import { useTranslation } from "react-i18next";
import { getReadableProfession } from "../functions/Users";
import { DashboardCard } from "../styles/dashboardStyles";
import styled from "styled-components";
import { colors } from "../utils/theme";
import { profile } from "../urls";
import LinkButton from "./LinkButton";

const ProfileDetail = ({ user, edit }) => {
  const { t } = useTranslation();
  return (
    <StyledDashboardCard>
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">{t("about")}</h4>
        <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">
          {!!user.about
            ? user.about
            : user.firstName + " heeft nog niks ingevuld."}
        </p>
      </div>
      <div className="card-body border-top-xs d-flex">
        <i className="feather-target me-3 font-lg" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {t("profession")}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {!!user.profession
              ? getReadableProfession(user.profession)
              : t("unemployed")}
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0">
        <i className="feather-phone me-3 font-lg" />
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          {t("phone_number")}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {!!user.phone ? user.phone : t("not_yet_set")}
          </span>
        </h4>
      </div>
      {!!user.skills && user.skills.length > 0 && (
        <div className="card-body d-flex pt-0">
          <i className="feather-heart me-3 font-lg" />
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">
            {t("skills")}
            {user.skills.map((skill, index) => {
              return (
                <span
                  className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                  key={index}
                >
                  {skill}
                </span>
              );
            })}
          </h4>
        </div>
      )}
      {edit && (
        <LinkButton to={profile} small type={"outlined"}>
          {t("edit")}
        </LinkButton>
      )}
    </StyledDashboardCard>
  );
};

const StyledDashboardCard = styled(DashboardCard)`
  i {
    color: ${colors.secondary};
  }
`;

export default ProfileDetail;
