import React from "react";
import HomeNavbar from "../components/Home/HomeNavbar";
import HomeFooter from "../components/Home/HomeFooter";
import { PageWrapper } from "../styles/sharedStyles";

const HomeLayout = ({ children }) => {
  return (
    <PageWrapper>
      <HomeNavbar />
      <main style={{ flex: 1 }}>{children}</main>
      <HomeFooter />
    </PageWrapper>
  );
};

export default HomeLayout;
