import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, message, Row, Select, Upload } from "antd";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { FIRESTORE_USERS_TABLE } from "../../constants/FirebaseConstants";
import styled from "styled-components";
import ImgCrop from "antd-img-crop";
import { STORAGE_AVATARS_LOCATION } from "../../constants/StorageConstants";
import { PROFESSIONS } from "../../constants/ProfessionConstants";
import { SKILLS } from "../../constants/SkillsConstants";
import { beforeImageUpload, dummyRequest } from "../../functions/Images";
import {
  DashboardFeedCard,
  DashboardSection,
  DashboardWrapper,
} from "../../styles/dashboardStyles";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";

const { Option } = Select;
const { TextArea } = Input;

const Profile = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const storage = firebase.storage();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const userProfile = useSelector((state) => state.firebaseReducer.profile);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!userProfile) {
      let firstName;
      let lastName = "";
      if (!userProfile.firstName) {
        if (!!userProfile.fullName) {
          const splitFullName = userProfile.fullName.split(" ");
          firstName = splitFullName[0];
          splitFullName.forEach(
            (substring, index) =>
              index !== 0 &&
              (lastName =
                substring + (index !== splitFullName.length - 1 ? " " : "")),
          );
        }
      } else {
        firstName = userProfile.firstName;
        lastName = userProfile.lastName;
      }
      form.setFieldsValue({
        firstName: firstName,
        lastName: lastName,
        email: userProfile.email,
        phone: userProfile.phone,
        profession: userProfile.profession,
        skills: userProfile.skills,
        about: userProfile.about,
      });
    }
  }, []);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (!values.skills) values.skills = [];
      if (!values.about) values.about = null;
      firestore
        .collection(FIRESTORE_USERS_TABLE)
        .doc(auth.uid)
        .update(values)
        .then(() => message.success(t("profile_saved")))
        .catch((err) => console.log(err));
    });
  };

  const saveAvatar = (file) => {
    const storageRef = storage.ref(STORAGE_AVATARS_LOCATION + "/" + auth.uid);
    storageRef.put(file.originFileObj).then((snapshot) => {
      storageRef.getDownloadURL().then((downloadUrl) => {
        firestore
          .collection(FIRESTORE_USERS_TABLE)
          .doc(auth.uid)
          .update({ avatar: downloadUrl })
          .then(() => message.success(t("notifications.avatar_saved")))
          .catch((err) => console.log(err));
      });
    });
  };

  const imageUploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        saveAvatar(info.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <DashboardLayout>
      <StyledDashboardWrapper>
        <DashboardSection narrow>
          <StyledDashboardCard>
            <div className="card-header card-body p-4 w-100 border-0 d-flex">
              <div
                className={"pt-2 pb-2 d-flex align-items-center cursor-pointer"}
                onClick={() => navigate(-1)}
              >
                <i className="ti-arrow-left font-sm text-white"></i>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0">
                  {t("go_back")}
                </h4>{" "}
              </div>
            </div>
            <div className="card-body p-lg-5 p-4 w-100 border-0 ">
              <div className="row justify-content-center">
                <div className="col-lg-4 text-center">
                  <ImgCrop
                    modalWidth={1000}
                    minZoom={0}
                    modalTitle={t("crop_image")}
                    modalOk={t("add")}
                    modalCancel={t("close")}
                    aspect={1}
                    cropperProps={{
                      restrictPosition: false,
                    }}
                  >
                    <Upload
                      {...imageUploadProps}
                      customRequest={dummyRequest}
                      beforeUpload={beforeImageUpload}
                      showUploadList={false}
                    >
                      <AvatarWrapper className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src={
                            !!userProfile.avatar
                              ? userProfile.avatar
                              : "/assets/images/user/placeholder.png"
                          }
                          alt="avatar"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </AvatarWrapper>
                    </Upload>
                  </ImgCrop>
                  <h2 className="fw-700 font-sm text-grey-900 mt-3">
                    {!userProfile.firstName
                      ? userProfile.fullName
                      : userProfile.firstName + " " + userProfile.lastName}
                  </h2>
                  <h4 className="text-grey-500 fw-500 mb-3 font-xsss mb-4">
                    {userProfile.email}
                  </h4>
                </div>
              </div>
              <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="firstName"
                      label={t("first_name")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_first_name"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="lastName"
                      label={t("last_name")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_last_name"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="email"
                      label={t("email")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_email"),
                        },
                        {
                          type: "email",
                          message: t("form.enter_valid_email"),
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="phone"
                      label={t("phone_number")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_phone"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="profession"
                      label={t("profession")}
                      rules={[
                        {
                          required: true,
                          message: t("form.select_profession"),
                        },
                      ]}
                    >
                      <Select placeholder={t("profession_placeholder")}>
                        {PROFESSIONS.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item[lang]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="skills" label={t("skills")}>
                      <Select mode="tags" placeholder={t("skills_placeholder")}>
                        {SKILLS.map((item, index) => {
                          return (
                            <Option key={index} value={item.id}>
                              {item[lang]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item name="about" label={t("user_description")}>
                      <TextArea
                        rows={4}
                        showCount
                        maxLength={500}
                        placeholder={t("about_user_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Button type={"primary"} htmlType={"submit"} block>
                  {t("save")}
                </Button>
              </Form>
            </div>
          </StyledDashboardCard>
        </DashboardSection>
      </StyledDashboardWrapper>
    </DashboardLayout>
  );
};

const StyledDashboardWrapper = styled(DashboardWrapper)`
  @media only screen and ${device.maxSm} {
    margin-top: 0;
    padding-inline: 0;
  }
`;

const StyledDashboardCard = styled(DashboardFeedCard)`
  padding: 0;
  margin: 0;

  .card-header {
    background-color: ${colors.primaryLight};
  }
`;

const AvatarWrapper = styled.figure`
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export default Profile;
