import React from "react";
import HomeLayout from "../layouts/HomeLayout";
import ContactForm from "../components/Forms/ContactForm";
import StyleElement from "../components/StyleElement";
import HireSpaceModal from "../components/Modal/HireSpaceModal";

const Contact = () => {
  return (
    <HomeLayout>
      <ContactForm />
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-06.svg"}
          alt={""}
          bottom={-25}
          right={75}
        />
      </div>
      <HireSpaceModal />
    </HomeLayout>
  );
};

export default Contact;
