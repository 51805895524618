import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import { notification } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { home, profile } from "../../urls";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  DashboardCard,
  DashboardSection,
  DashboardWrapper,
} from "../../styles/dashboardStyles";
import { colors } from "../../utils/theme";

const Settings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firebase = useFirebase();

  const handleLogout = () => {
    firebase.logout().then(() => {
      notification["success"]({
        message: t("notifications.logged_out"),
        duration: 2,
      });
      navigate(home);
    });
  };
  return (
    <DashboardLayout>
      <DashboardWrapper>
        <DashboardSection narrow>
          <StyledDashboardCard>
            <div className="card-body p-lg-5 p-4 w-100 border-0">
              <div className="row">
                <div className="col-lg-12">
                  <h1 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                    {t("settings")}
                  </h1>
                  <ul className="list-inline mb-4">
                    <li className="list-inline-item d-block border-bottom me-0">
                      <Link
                        to={profile}
                        className="pt-2 pb-2 d-flex align-items-center"
                      >
                        <i className="btn-round-md text-white feather-user font-md me-3"></i>{" "}
                        <h4 className="fw-600 font-xsss mb-0 mt-0">
                          {t("profile")}
                        </h4>
                        <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                      </Link>
                    </li>
                    <li className="list-inline-item d-block border-bottom me-0">
                      <a
                        href={
                          "https://billing.stripe.com/p/login/7sI5km8a2fxreB2000"
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                        className="pt-2 pb-2 d-flex align-items-center"
                      >
                        <i className="btn-round-md text-white font-md me-3 stripe">
                          <img src={"/assets/images/icons/stripe.svg"} />
                        </i>{" "}
                        <h4 className="fw-600 font-xsss mb-0 mt-0">
                          {t("to_stripe_account")}
                        </h4>
                        <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                      </a>
                    </li>
                    <li className="list-inline-item d-block me-0">
                      <ClickableDiv
                        className={"pt-2 pb-2 d-flex align-items-center"}
                        onClick={handleLogout}
                      >
                        <i className="btn-round-md text-white feather-log-out font-md me-3"></i>{" "}
                        <h4 className="fw-600 font-xsss mb-0 mt-0">
                          {t("logout")}
                        </h4>
                        <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                      </ClickableDiv>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </StyledDashboardCard>
        </DashboardSection>
      </DashboardWrapper>
    </DashboardLayout>
  );
};

const StyledDashboardCard = styled(DashboardCard)`
  i.feather-user,
  i.stripe {
    background-color: ${colors.primaryLight};
  }

  i.stripe {
    display: grid;
    place-content: center;
    img {
      width: 32px;
      aspect-ratio: 1;
    }
  }

  i.feather-log-out {
    background-color: ${colors.link};
  }
`;
const ClickableDiv = styled.div`
  cursor: pointer;
`;

export default Settings;
