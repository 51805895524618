import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import StyledButton from "../StyledButton";
import { toggleHireSpaceModal } from "../../redux/actions/modalActions";
import LinkButton from "../LinkButton";
import { contact } from "../../urls";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  BannerDescription,
  BannerTitle,
  Image,
} from "../../styles/sharedStyles";

const ImageAndTextBanner = ({
  src,
  alt,
  title,
  description,
  btnText,
  flip = false,
  styleElement = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="section position-relative" id="feature">
      <div className="container">
        <div className="row">
          <div className={`col-lg-7 ${flip ? "order-lg-1" : ""}`}>
            <Image src={src} alt={alt} className="img-fluid" />
          </div>
          {styleElement && (
            <ButterflyStyleElement
              src={"assets/images/illustrations/illustratie-07.svg"}
            />
          )}
          <div className="col-lg-5 align-self-center">
            <StyledBannerTitle>
              <b>{title}</b>
            </StyledBannerTitle>
            <BannerDescription>{description}</BannerDescription>
            <div className={"d-flex mt-3"}>
              <StyledButton onClick={() => dispatch(toggleHireSpaceModal())}>
                {btnText || t("rent_a_space")}
              </StyledButton>
              <LinkButton to={contact} small type={"outlined"}>
                {t("get_in_touch")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ButterflyStyleElement = styled.img`
  position: absolute;
  width: 180px;
  right: 15vw;
  bottom: 70vh;
  z-index: 0;

  @media ${device.maxXl} {
    bottom: -20vh;
  }

  @media ${device.maxLg} {
    display: none;
  }
`;

const StyledBannerTitle = styled(BannerTitle)`
  margin-bottom: 0.1rem;
`;

export default ImageAndTextBanner;
