export const colors = {
  primary: "#042410",
  primaryMedium: "#1E5C32",
  primaryLight: "#6FBD8D",
  secondary: "#F30584",
  secondaryLight: "#F668B2",
  secondaryDark: "#7b0e47",
  header: "#1E5C32",
  light: "#F1E8D8",
  text: "#3B3F33",
  btn: "#4B5A57",
  cta_btn: "#7b0e47",
  cta_btn_text: "#fff",
  link: "#7B0E47",
  menu_items: "#222",
  hamburger: "#005F59",
  bg_green: "#62906F",
};

export const styles = {
  btn_border_radius: "4px",
  card_border_radius: "6px",
};
