import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors, styles } from "../utils/theme";
import { transparentize } from "../utils/helperFn";

const MembersSearchBar = (props) => {
  const { title, onSearch } = props;
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <h2 className="fw-700 mb-0 mt-0 font-md text-grey-900 d-flex align-items-center">
        {title}
        <form
          className="pt-0 pb-0 ms-auto"
          onSubmit={(e) => e.preventDefault()}
        >
          <div className="search-form-2 ms-2">
            <i className="ti-search font-xss" />
            <input
              type="text"
              className="form-control text-grey-500 mb-0 bg-white theme-dark-bg border-0"
              placeholder={t("placeholders.search")}
              onChange={onSearch}
            />
          </div>
        </form>
      </h2>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  background-color: ${transparentize("#ffffff", 0.6)};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;
  height: fit-content;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: ${styles.card_border_radius};
  border: 1px solid ${colors.bg_green};
  margin-bottom: 0;
  backdrop-filter: blur(6px);

  input {
    caret-color: ${colors.secondary};
  }
`;

export default MembersSearchBar;
