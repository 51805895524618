import dayjs from "dayjs";

export const getDisabledDatesForEndDateEvent = (start, current) => {
  const startDate = dayjs(start).startOf("day");
  const currentDate = dayjs(current).startOf("day");
  return startDate > currentDate;
};

export const getDisableDatesForMeetingRoom = (current) => {
  const today = dayjs();
  return current <= today;
};
