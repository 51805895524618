import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createSubscription } from "../../urls";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/Loader";
import { message } from "antd";
import styled from "styled-components";
import StyledContainer from "../../components/Subscription/StyledContainer";
import TextContainer from "../../components/Subscription/TextContainer";

const RegisterSubscription = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleRegister = () => {
    setIsLoading(true);
    fetch(createSubscription, {
      method: "POST",
    })
      .then((response) => {
        return response.json();
      })
      .then((redirectUrl) => {
        if (typeof redirectUrl !== "string") {
          setIsLoading(false);
          message.error(redirectUrl.message);
        } else window.location.replace(redirectUrl);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return isLoading ? (
    <StyledContainer>
      <Loader />
    </StyledContainer>
  ) : (
    <>
      <StyledContainer className={"container"}>
        <TextContainer>
          <Logo src={"/assets/images/logo/logo-128.png"} />
          <h4>{t("create_subscription_title")}</h4>
          <p>{t("create_subscription_description")}</p>
          <Link to={"#"} onClick={handleRegister}>
            {t("register")}
          </Link>
        </TextContainer>
      </StyledContainer>
    </>
  );
};

const Logo = styled.img`
  max-width: 120px;
`;

export default RegisterSubscription;
