import React from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";

const StyledContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled(Container)`
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
`;

export default StyledContainer;
