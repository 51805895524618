import React from "react";
import HomeLayout from "../layouts/HomeLayout";
import { useTranslation } from "react-i18next";
import InfoText from "../components/Banners/InfoText";
import privacyData from "../data/info/privacy-data.json";

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  const DATA = privacyData[i18n.language];
  return (
    <HomeLayout>
      <InfoText data={DATA} />
    </HomeLayout>
  );
};

export default PrivacyPolicy;
