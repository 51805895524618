import React, { useMemo } from "react";
import EllipsisDropdown from "./Antd/EllipsisDropdown";
import { Input, Menu, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { userProfileRaw } from "../urls";
import dayjs from "dayjs";
import ReactPlayer from "react-player";
import { colors } from "../utils/theme";
import { getUserById } from "../functions/Users";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import _ from "lodash";
import { FIRESTORE_FEED_POSTS_TABLE } from "../constants/FirebaseConstants";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { DashboardFeedCard } from "../styles/dashboardStyles";

export default function Post({
  index,
  post,
  user,
  handleButton,
  handlePin,
  expanded,
  comment,
  setComment,
}) {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const users = useSelector((state) => state.firestoreReducer.ordered.users);
  const uid = auth.uid;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { comments } = post;
  const author = useMemo(
    () => getUserById(users, post.authorId),
    [users, post.authorId],
  );
  const like = useMemo(
    () => _.find(post.likes, (item) => item.authorId === uid),
    [post.likes, uid],
  );

  const handleUpdateLike = (post, like) => {
    if (like) {
      const clonedPostLikes = _.clone(post.likes);
      const likeIndex = _.findIndex(clonedPostLikes, {
        createdAt: like.createdAt,
        authorId: like.authorId,
      });
      _.pullAt(clonedPostLikes, [likeIndex]);
      firestore
        .collection(FIRESTORE_FEED_POSTS_TABLE)
        .doc(post.id)
        .update({ likes: clonedPostLikes })
        .catch((err) => console.log(err));
    } else {
      let updatedLikes = [];
      const newLike = {
        authorId: uid,
        createdAt: dayjs().unix(),
      };
      if (!!post.likes) {
        updatedLikes = _.clone(post.likes);
      }
      updatedLikes.push(newLike);
      firestore
        .collection(FIRESTORE_FEED_POSTS_TABLE)
        .doc(post.id)
        .update({ likes: updatedLikes })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmitComment = (post) => {
    if (!!comment) {
      let updatedComments = _.clone(post.comments);
      const newComment = {
        text: comment,
        authorId: uid,
        createdAt: dayjs().unix(),
      };
      updatedComments.push(newComment);
      firestore
        .collection(FIRESTORE_FEED_POSTS_TABLE)
        .doc(post.id)
        .update({ comments: updatedComments })
        .then(() => {
          setComment("");
        })
        .catch((err) => console.log(err));
    }
  };

  const handleDeletePost = (post) => {
    firestore
      .collection(FIRESTORE_FEED_POSTS_TABLE)
      .doc(post.id)
      .delete()
      .then(() => {
        if (!!post.images && post.images.length > 0) {
          post.images.forEach((imageUrl) => {
            const fileRef = firebase.storage().refFromURL(imageUrl);
            fileRef.delete().catch((error) => console.log(error));
          });
        }
        if (!!post.audio) {
          const fileRef = firebase.storage().refFromURL(post.audio);
          fileRef.delete().catch((error) => console.log(error));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteComment = (post, comment) => {
    const clonedPostComments = _.clone(post.comments);
    const commentIndex = _.findIndex(clonedPostComments, {
      createdAt: comment.createdAt,
      text: comment.text,
      authorId: comment.authorId,
    });
    if (commentIndex !== -1) {
      // Remove comment from array
      _.pullAt(clonedPostComments, [commentIndex]);
      // Save new array to feedPost
      firestore
        .collection(FIRESTORE_FEED_POSTS_TABLE)
        .doc(post.id)
        .update({ comments: clonedPostComments })
        .catch((err) => console.log(err));
    }
  };

  if (!!auth && !!author)
    return (
      <DashboardFeedCard>
        {post.pin && (
          <PostPin src={"/assets/images/icons/bookmark.svg"} alt={""} />
        )}
        {profile.role === "admin" ? (
          <PostOptions>
            <EllipsisDropdown
              size={20}
              menu={
                <Menu>
                  {post.pin ? (
                    <Menu.Item>
                      <div
                        className="d-flex align-items-center"
                        onClick={() => handlePin(post)}
                      >
                        <i className="feather-bookmark text-dark text-grey-900 font-xsss" />
                        <span style={{ marginLeft: 4 }}>{t("remove_pin")}</span>
                      </div>
                    </Menu.Item>
                  ) : (
                    <Menu.Item>
                      <div
                        className="d-flex align-items-center"
                        onClick={() => handlePin(post)}
                      >
                        <i className="feather-bookmark text-dark text-grey-900 font-xsss" />
                        <span style={{ marginLeft: 4 }}>{t("pin_post")}</span>
                      </div>
                    </Menu.Item>
                  )}

                  {post.authorId === uid && (
                    <Menu.Item>
                      <div className="d-flex align-items-center">
                        <DeleteOutlined />
                        <Popconfirm
                          placement="bottom"
                          title={t("confirm_delete_post")}
                          onConfirm={() => handleDeletePost(post)}
                          okText={t("yes")}
                          cancelText={t("no")}
                        >
                          <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                        </Popconfirm>
                      </div>
                    </Menu.Item>
                  )}
                </Menu>
              }
            />
          </PostOptions>
        ) : (
          post.authorId === uid && (
            <PostOptions>
              <EllipsisDropdown
                size={20}
                menu={
                  <Menu>
                    <Menu.Item>
                      <div className="d-flex align-items-center">
                        <DeleteOutlined />
                        <Popconfirm
                          placement="bottom"
                          title={t("confirm_delete_post")}
                          onConfirm={() => handleDeletePost(post)}
                          okText={t("yes")}
                          cancelText={t("no")}
                        >
                          <span style={{ marginLeft: 4 }}>{t("delete")}</span>
                        </Popconfirm>
                      </div>
                    </Menu.Item>
                  </Menu>
                }
              />
            </PostOptions>
          )
        )}
        <div className="card-body p-0 d-flex">
          <div className={"d-flex"}>
            <figure className="avatar me-3">
              <img
                src={
                  !!author.avatar
                    ? author.avatar
                    : "/assets/images/user/placeholder.png"
                }
                alt={author.firstName + " " + author.lastName}
                className="shadow-sm rounded-circle w45"
              />
            </figure>
            <Link to={userProfileRaw + author.id}>
              <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                {author.firstName + " " + author.lastName}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {dayjs.unix(post.createdAt).fromNow()}
                </span>
              </h4>
            </Link>
          </div>
        </div>
        <div className="card-body p-0 me-lg-5">
          <p className="fw-500 text-grey-700 lh-26 font-xssss w-100 mb-2">
            {post.text}
          </p>
        </div>
        {!!post.images && post.images.length > 0 && (
          <div className="card-body d-block p-0 mb-3">
            <div className="row ps-2 pe-2">
              <div className="col-sm-12 p-1">
                <img
                  src={post.images[0]}
                  className="rounded-3 w-100"
                  alt="post"
                />
              </div>
            </div>
          </div>
        )}
        {!!post.video && (
          <div className="card-body d-block p-0 mb-3">
            <div className="row ps-2 pe-2">
              <div className="col-sm-12 p-1">
                <VideoPlayer>
                  <ReactPlayer
                    url={post.video}
                    width={"100%"}
                    controls={true}
                  />
                </VideoPlayer>
              </div>
            </div>
          </div>
        )}
        {!!post.audio && (
          <div className="card-body d-block p-0 mb-3">
            <div className="row ps-2 pe-2">
              <div className="col-sm-12 p-1">
                <ReactPlayer
                  url={post.audio}
                  width={"100%"}
                  height={48}
                  controls={true}
                  config={{
                    file: {
                      attributes: {
                        controlsList: "nodownload",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div className="card-body d-flex p-0">
          <CommentsBtn
            onClick={() => handleUpdateLike(post, like)}
            className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
          >
            {like ? (
              <i
                className="feather-thumbs-up btn-round-sm font-lg"
                style={{ color: colors.primaryLight }}
              />
            ) : (
              <i className="feather-thumbs-up text-dark text-grey-900 btn-round-sm font-lg" />
            )}
            <span className="d-none-xss">
              {t("likes_amount", { amount: post.likes?.length || 0 })}
            </span>
          </CommentsBtn>
          <CommentsBtn
            onClick={() => handleButton(index)}
            className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
          >
            <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg" />
            <span className="d-none-xss">
              {t("comment_amount", { amount: post.comments.length })}
            </span>
          </CommentsBtn>
        </div>
        <CommentSection visible={expanded === index}>
          <WriteCommentWrapper>
            <img
              src={
                !!user.avatar
                  ? user.avatar
                  : "/assets/images/user/placeholder.png"
              }
              alt={user.firstName + " " + user.lastName}
            />
            <Input
              rows={1}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              onPressEnter={() => handleSubmitComment(post)}
              placeholder={t("write_comment_placeholder")}
            />
          </WriteCommentWrapper>
          {comments.length > 0 &&
            comments.map((comment, i) => {
              const author = getUserById(users, comment.authorId);
              if (!!author)
                return (
                  <CommentWrapper key={i}>
                    <SingleComment>
                      <div className={"d-flex"}>
                        <img
                          src={
                            !!author && !!author.avatar
                              ? author.avatar
                              : "/assets/images/user/placeholder.png"
                          }
                          alt={
                            !!author
                              ? author.firstName + " " + author.lastName
                              : ""
                          }
                        />
                        <div className={"d-block"}>
                          <CommentBubble>
                            <CommentAuthor>
                              {author.firstName + " " + author.lastName}
                            </CommentAuthor>
                            <p>{comment.text}</p>
                          </CommentBubble>
                          <CommentDetails>
                            {/*<h4>{t("reply")}</h4>*/}
                            <p>{dayjs.unix(comment.createdAt).fromNow()}</p>
                          </CommentDetails>
                        </div>
                      </div>
                      {comment.authorId === uid && (
                        <EllipsisDropdown
                          size={20}
                          menu={
                            <Menu>
                              <Menu.Item
                                onClick={() =>
                                  handleDeleteComment(post, comment)
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <DeleteOutlined />
                                  <span style={{ marginLeft: 4 }}>
                                    {t("delete")}
                                  </span>
                                </div>
                              </Menu.Item>
                            </Menu>
                          }
                        />
                      )}
                    </SingleComment>
                  </CommentWrapper>
                );
              else return null;
            })}
        </CommentSection>
      </DashboardFeedCard>
    );
}

const PostPin = styled.img`
  position: absolute;
  top: -3px;
  left: 0.5rem;
  width: 24px;
`;

const VideoPlayer = styled.div`
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
`;

const PostOptions = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const CommentDetails = styled.div`
  display: flex;
  margin-left: 6px;

  h4 {
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
    margin-right: 0.75rem;
    cursor: pointer;
  }

  p {
    font-size: 11px !important;
    line-height: 16px !important;
  }
`;

const CommentBubble = styled.div`
  background-color: #f4f4f4;
  padding: 2px 8px;
  border-radius: 12px;
  width: fit-content;
`;

const CommentAuthor = styled.p`
  font-size: 12px !important;
  line-height: 17px !important;
  font-weight: 500;
  margin-bottom: 0;
`;

const SingleComment = styled.div`
  display: flex;
  justify-content: space-between;

  img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  p {
    border-radius: 12px;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 0;
  }
`;

const CommentWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const WriteCommentWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  img {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 0.5rem;
  }

  input {
    border-radius: 20px;
  }
`;

const CommentSection = styled.div`
  display: ${({ visible }) => (visible ? "block" : "none")};
  border-top: 1px solid lightgrey;
  margin-top: 0.5rem;
  padding-top: 1rem;
`;

const CommentsBtn = styled.div`
  cursor: pointer;
`;
