import styled from "styled-components";
import { colors, styles } from "../utils/theme";
import { Link } from "react-router-dom";
import { transparentize } from "../utils/helperFn";
import { device } from "../utils/breakpoints";

const Button = styled(Link)`
  border-radius: ${styles.btn_border_radius};
  padding: ${({ small }) => (small ? "3px 12px" : "1.2rem")};
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: 0.2s all ease;
  display: inline-block;
  text-align: center;

  @media ${device.lg} {
    font-size: ${({ small }) => (small ? "14px" : "20px")};
  }
`;

const PrimaryButton = styled(Button)`
  background-color: ${colors.secondary};
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background-color: ${transparentize(colors.secondary, 0.6)};
    color: #fff;
  }
`;

const OutlinedButton = styled(Button)`
  background-color: transparent;
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.secondary};
    color: #fff;
  }
`;

const LinkButton = ({
  type = "primary",
  small = false,
  children,
  ...props
}) => {
  switch (type) {
    case "primary":
      return (
        <PrimaryButton small {...props}>
          {children}
        </PrimaryButton>
      );
    case "outlined":
      return (
        <OutlinedButton small {...props}>
          {children}
        </OutlinedButton>
      );
    default:
      return (
        <PrimaryButton small {...props}>
          {children}
        </PrimaryButton>
      );
  }
};

export default LinkButton;
