import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Input, message, Upload } from "antd";
import { useFirebase, useFirestore } from "react-redux-firebase";
import { FIRESTORE_FEED_POSTS_TABLE } from "../constants/FirebaseConstants";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  beforeAudioUpload,
  beforeImageUpload,
  dummyRequest,
} from "../functions/Images";
import ImgCrop from "antd-img-crop";
import { STORAGE_FEED_POSTS_LOCATION } from "../constants/StorageConstants";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { isValidHttpUrl } from "../functions/Browser";
import ReactPlayer from "react-player";
import { DashboardFeedCard } from "../styles/dashboardStyles";

const CreatePost = () => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const storage = firebase.storage();
  const firestore = useFirestore();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const uid = auth.uid;
  const [postText, setPostText] = useState("");
  const [postImage, setPostImage] = useState(null);
  const [postAudio, setPostAudio] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoInputVisible, setVideoInputVisible] = useState(false);
  const [videoUrlInput, setVideoUrlInput] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);

  const handleSubmit = () => {
    if (isValidPost) {
      setIsLoading(true);
      const now = dayjs().unix();
      const object = {
        authorId: uid,
        text: postText,
        createdAt: now,
        comments: [],
        images: [],
        video: !!videoUrl ? videoUrl : null,
      };
      if (!!postImage || !!postAudio) {
        const file = !!postImage ? postImage : postAudio;
        const fileName = file.uid + "-" + now;
        const storageRef = storage.ref(
          STORAGE_FEED_POSTS_LOCATION + "/" + fileName,
        );
        storageRef.put(file.originFileObj).then(() => {
          storageRef
            .getDownloadURL()
            .then((downloadUrl) => {
              createFirestoreFeedPost(
                object,
                !!postImage ? downloadUrl : null,
                !!postAudio ? downloadUrl : null,
              );
            })
            .catch((err) => console.log(err));
        });
      } else {
        createFirestoreFeedPost(object);
      }
    }
  };

  const isValidPost = () => {
    return !!postText || !!postImage || !!postAudio || !!videoUrl;
  };

  const createFirestoreFeedPost = (object, imageUrl, audioUrl) => {
    if (!!imageUrl) object.images[0] = imageUrl;
    if (!!audioUrl) object.audio = audioUrl;
    firestore
      .collection(FIRESTORE_FEED_POSTS_TABLE)
      .add(object)
      .then(() => {
        setPostText("");
        setPostImage(null);
        setPostAudio(null);
        setVideoInputVisible(false);
        setVideoUrlInput("");
        setVideoUrl(null);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleClickVideo = () => {
    if (videoInputVisible) {
      setVideoInputVisible(false);
      setVideoUrlInput("");
      setVideoUrl(null);
    } else setVideoInputVisible(true);
  };

  const handleSetVideoUrl = () => {
    setVideoUrl(videoUrlInput);
  };

  const imageUploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        setPostImage(info.file);
        setPostAudio(null);
      } else if (info.file.status === "error")
        message.error(`${info.file.name} file upload failed.`);
      else if (info.file.status === "removed") setPostImage(null);
    },
  };

  const audioUploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        setPostAudio(info.file);
        setPostImage(null);
      } else if (info.file.status === "error")
        message.error(`${info.file.name} file upload failed.`);
      else if (info.file.status === "removed") setPostAudio(null);
    },
  };

  return (
    <DashboardFeedCard>
      <div className="card-body p-0 mt-3 position-relative">
        <Input.TextArea
          rows={4}
          showCount
          maxLength={480}
          onChange={(e) => setPostText(e.target.value)}
          value={postText}
          placeholder={t("placeholders.create_feed_post")}
        />
      </div>
      <div className="card-body d-flex p-0 mt-4 w-100 justify-content-between">
        <div className={"d-flex"}>
          <ImgCrop
            modalWidth={1000}
            minZoom={0}
            modalTitle={t("crop_image")}
            modalOk={t("add")}
            modalCancel={t("close")}
            aspect={16 / 9}
            cropperProps={{
              restrictPosition: false,
            }}
          >
            <Upload
              {...imageUploadProps}
              customRequest={dummyRequest}
              beforeUpload={beforeImageUpload}
              showUploadList={false}
            >
              <i className="font-md text-success feather-image me-1" />
              <span className="d-none-xs">{t("photo")}</span>
            </Upload>
          </ImgCrop>
          <VideoButton onClick={handleClickVideo}>
            <i className="font-md text-warning feather-video me-1 ms-3" />
            <span className="d-none-xs">{t("video")}</span>
          </VideoButton>
          <Upload
            {...audioUploadProps}
            customRequest={dummyRequest}
            beforeUpload={beforeAudioUpload}
            showUploadList={false}
          >
            <i className="font-md text-secondary feather-music me-1 ms-3" />
            <span className="d-none-xs">{t("audio")}</span>
          </Upload>
        </div>
        <Button
          type={"primary"}
          disabled={!isValidPost}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {t("publish_post_btn")}
        </Button>
      </div>
      {videoInputVisible && (
        <div className={"d-flex mt-3"}>
          <Input
            onChange={(e) => setVideoUrlInput(e.target.value)}
            value={videoUrlInput}
            placeholder={t("placeholders.video_url")}
            className={"me-2"}
          />
          <Button
            onClick={handleSetVideoUrl}
            disabled={!isValidHttpUrl(videoUrlInput)}
            type="primary"
          >
            <CheckOutlined />
          </Button>
        </div>
      )}
      {!!postImage && (
        <div className={"d-flex mt-3"}>
          {postImage.name}
          <DeleteButton onClick={() => setPostImage(null)}>
            <DeleteOutlined />
          </DeleteButton>
        </div>
      )}
      {!!postAudio && (
        <div className={"d-flex mt-3"}>
          {postAudio.name}
          <DeleteButton onClick={() => setPostAudio(null)}>
            <DeleteOutlined />
          </DeleteButton>
        </div>
      )}
      {!!videoUrl && (
        <VideoPreview>
          <ReactPlayer
            url={videoUrl}
            width={"100%"}
            controls={true}
            style={{ borderRadius: 6 }}
          />
        </VideoPreview>
      )}
    </DashboardFeedCard>
  );
};

const VideoPreview = styled.div`
  margin-top: 1rem;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
`;

const VideoButton = styled.div`
  display: flex;
  cursor: pointer;
`;

const DeleteButton = styled.div`
  display: flex;
  padding: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 100%;
  margin-left: 0.5rem;

  &:hover {
    background-color: #e3e3e3;
  }
`;

export default CreatePost;
