import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const DigitalKissLogo = () => {
  const { t } = useTranslation();
  return (
    <LogoContainer>
      <p>{t("developed_by")}</p>
      <a href={"https://digitalkiss.nl"} target={"_blank"} rel={"noreferrer"}>
        <img
          className={"dk-logo"}
          src={"/assets/images/logo/logo-digital-kiss.png"}
          alt={"Digital Kiss logo"}
        />
      </a>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  p {
    text-align: center;
    margin-bottom: 0;
  }

  img {
    width: 120px;
  }
`;
export default DigitalKissLogo;
