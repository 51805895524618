import React, { useCallback, useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import styled from "styled-components";
import {
  Badge,
  Button,
  Calendar,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Upload,
} from "antd";
import { transparentize } from "../../utils/helperFn";
import { colors, styles } from "../../utils/theme";
import useIsMobile from "../../components/Hooks/useIsMobile";
import { useSelector } from "react-redux";
import { clone, filter, sortBy } from "lodash";
import dayjs from "dayjs";
import ImgCrop from "antd-img-crop";
import { beforeImageUpload, dummyRequest } from "../../functions/Images";
import { UploadOutlined } from "@ant-design/icons";
import locale from "antd/es/date-picker/locale/nl_NL";
import { getDisabledDatesForEndDateEvent } from "../../functions/Datepicker";
import { useTranslation } from "react-i18next";
import { STORAGE_EVENTS_LOCATION } from "../../constants/StorageConstants";
import { FIRESTORE_EVENTS_TABLE } from "../../constants/FirebaseConstants";
import { useFirebase, useFirestore } from "react-redux-firebase";
import ReactMarkdown from "react-markdown";
import { device } from "../../utils/breakpoints";
import { DashboardCard } from "../../styles/dashboardStyles";
import { Link } from "react-router-dom";
import { userProfileRaw } from "../../urls";

const { TextArea } = Input;

export default function Agenda() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const users = useSelector((state) => state.firestoreReducer.ordered.users);
  const events = useSelector((state) => state.firestoreReducer.ordered.events);
  const [showEventModalVisible, setShowEventModalVisible] = useState(false);
  const [createEventModalVisible, setCreateEventModalVisible] = useState(false);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const storage = firebase.storage();
  const [createEventForm] = Form.useForm();
  const [editEventForm] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [editing, setEditing] = useState(false);
  const [isPrivate, setIsPrivate] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [mobileEventsList, setMobileEventsList] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [isYear, setIsYear] = useState(false);

  const createMobileEventsList = useCallback(
    (value, unit) => {
      const list = sortBy(
        filter(events, (item) => {
          return dayjs.unix(item.startDate).isSame(value, unit);
        }),
        "start"
      );
      setMobileEventsList(list);
      if (unit === "date") {
        setShowDate(false);
      } else {
        setShowDate(true);
      }
    },
    [events]
  );

  useEffect(() => {
    createMobileEventsList(dayjs(), "month");
  }, [events, createMobileEventsList]);

  const imageUploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    onChange(info) {
      if (info.file.status === "done") {
        setUploadedImage(info.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      } else if (info.file.status === "removed") setUploadedImage(null);
    },
  };

  const handlePanelChange = (date, mode) => {
    switch (mode) {
      case "year":
        setIsYear(true);
        break;
      default:
        setIsYear(false);
    }
    createMobileEventsList(date, mode);
  };

  const handleCreateEvent = () => {
    createEventForm.validateFields().then((values) => {
      const object = {
        type: values.type,
        title: values.title,
        startDate: dayjs(values.startDate).unix(),
        endDate: dayjs(values.endDate).unix(),
        description: values.description || null,
        location: values.location,
        private: values.private || false,
        createdBy: auth.uid,
        createdAt: dayjs().unix(),
      };
      delete values.image;
      if (!!uploadedImage) {
        const fileName = values.title + "-" + dayjs(startDate).unix();
        const storageRef = storage.ref(
          STORAGE_EVENTS_LOCATION + "/" + fileName
        );
        storageRef.put(uploadedImage.originFileObj).then((snapshot) => {
          storageRef.getDownloadURL().then((downloadUrl) => {
            saveEventToFirestore(object, downloadUrl);
          });
        });
      } else {
        saveEventToFirestore(object, null);
      }
    });
  };

  const saveEventToFirestore = (event, downloadUrl) => {
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .add({ ...event, thumbnail: !!downloadUrl ? downloadUrl : null })
      .then(() => closeCreateEventModal())
      .catch((err) => console.log(err));
  };

  const closeCreateEventModal = () => {
    setCreateEventModalVisible(false);
    setUploadedImage(null);
    setIsPrivate(false);
    setStartDate(null);
    createEventForm.resetFields();
  };

  const openEditModal = () => {
    editEventForm.setFieldsValue({
      type: currentEvent.type,
      title: currentEvent.title,
      eventDate: [dayjs.unix(currentEvent.start), dayjs.unix(currentEvent.end)],
      startDate: dayjs.unix(currentEvent.startDate),
      endDate: dayjs.unix(currentEvent.endDate),
      location: currentEvent.location,
      description: currentEvent.description || null,
    });
    setIsPrivate(currentEvent.private);
    setEditing(true);
  };

  const handleEditEvent = () => {
    editEventForm.validateFields().then((values) => {
      const object = {
        type: values.type,
        title: values.title,
        startDate: dayjs(values.startDate).unix(),
        endDate: dayjs(values.endDate).unix(),
        description: values.description || null,
        location: values.location,
        private: values.private || isPrivate,
        updatedAt: dayjs().unix(),
      };
      delete values.image;
      if (!!uploadedImage) {
        const fileName = values.title + "-" + dayjs(startDate).unix();
        const storageRef = storage.ref(
          STORAGE_EVENTS_LOCATION + "/" + fileName
        );
        storageRef.put(uploadedImage.originFileObj).then((snapshot) => {
          storageRef.getDownloadURL().then((downloadUrl) => {
            updateEventToFirestore(object, downloadUrl);
          });
        });
      } else {
        updateEventToFirestore(object);
      }
    });
  };

  const updateEventToFirestore = (event, downloadUrl) => {
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .doc(currentEvent.id)
      .update({ ...event, thumbnail: downloadUrl || currentEvent.thumbnail })
      .then(() => {
        closeEditModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeEditModal = () => {
    setShowEventModalVisible(false);
    setEditing(false);
    setCurrentEvent(null);
    setUploadedImage(null);
    setSubmitLoading(false);
    editEventForm.resetFields();
  };

  const handleClickItem = (e, event) => {
    e.stopPropagation();
    let clonedEvent = clone(event);
    setCurrentEvent(clonedEvent);
    setShowEventModalVisible(true);
  };

  const handleDeleteEvent = () => {
    setSubmitLoading(true);
    firestore
      .collection(FIRESTORE_EVENTS_TABLE)
      .doc(currentEvent.id)
      .delete()
      .then(() => {
        setSubmitLoading(false);
        closeEditModal();
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoading(false);
      });
  };

  const onSelectCalendarDate = (date, info) => {
    if (isMobile) {
      setCurrentDate(date);
      createMobileEventsList(date, info.source);
    } else if (info.source === "date") {
      createEventForm.setFieldsValue({ startDate: date });
      setCurrentDate(date);
      setCreateEventModalVisible(true);
    }
  };

  const createNewActivity = () => {
    if (mobileEventsList.length > 0) {
      createEventForm.setFieldsValue({ startDate: currentDate });
      setCreateEventModalVisible(true);
    } else {
      setCreateEventModalVisible(true);
    }
  };

  const dateCellRender = (value, unit) => {
    const list = sortBy(
      filter(events, (item) => {
        return dayjs.unix(item.startDate).isSame(value, unit);
      }),
      "start"
    );

    return (
      <Events>
        {!!list &&
          list.length > 0 &&
          list.map((item, index) => {
            return (
              <li key={item.id} onClick={(e) => handleClickItem(e, item)}>
                <Badge color={setItemColor(item.type)} text={item.title} />
                {unit === "month" && (
                  <span className="d-flex font-xssss fw-500 lh-3 text-grey-500 date">
                    {dayjs.unix(item.startDate).format("DD MMM")}
                  </span>
                )}
              </li>
            );
          })}
      </Events>
    );
  };

  const setItemColor = (item) => {
    switch (item) {
      case "event":
        return colors.secondary;
      case "meeting":
        return colors.primaryLight;
      case "announcement":
        return colors.secondaryLight;
      case "personally":
        return colors.primaryMedium;
      default:
        return colors.primaryLight;
    }
  };

  const cellRender = (current, info) => {
    return dateCellRender(current, info.type);
  };

  return (
    <DashboardLayout>
      <AgendaWrapper>
        <StyledCalendar
          cellRender={cellRender}
          onPanelChange={handlePanelChange}
          fullscreen={!isMobile}
          onSelect={onSelectCalendarDate}
          locale={locale}
        />
      </AgendaWrapper>
      {isMobile && (
        <MobileEventsList isYear={isYear}>
          {mobileEventsList.length > 0 ? (
            <ul>
              {mobileEventsList.map((item) => (
                <li key={item.id} onClick={(e) => handleClickItem(e, item)}>
                  <Badge color={setItemColor(item.type)} text={item.title} />
                  <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                    {dayjs
                      .unix(item.startDate)
                      .format(showDate ? "DD MMM HH:mm" : "HH:mm")}
                    {" - "}
                    {dayjs
                      .unix(item.startDate)
                      .isSame(dayjs.unix(item.endDate), "day")
                      ? dayjs.unix(item.endDate).format("HH:mm")
                      : dayjs.unix(item.endDate).format("DD MMM HH:mm")}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>{t("no_activities")}</p>
          )}
          <div className={"button-container"}>
            <Button type="primary" onClick={createNewActivity}>
              {t("create_activity")}
            </Button>
          </div>
        </MobileEventsList>
      )}
      {/* Create event modal */}
      <Modal
        title={t("create_activity")}
        open={createEventModalVisible}
        onCancel={closeCreateEventModal}
        onOk={handleCreateEvent}
        okText={t("save")}
        cancelText={t("close")}
      >
        <Form layout="vertical" form={createEventForm}>
          <Row gutter={16}>
            <Col lg={16}>
              <Form.Item
                name="type"
                label={t("activity_type")}
                rules={[
                  { required: true, message: t("form.enter_event_type") },
                ]}
              >
                <Select placeholder={t("form.select_event_type")}>
                  <Select.Option value="meeting">{t("meeting")}</Select.Option>
                  <Select.Option value="event">{t("event")}</Select.Option>
                  <Select.Option value="announcement">
                    {t("announcement")}
                  </Select.Option>
                  <Select.Option value="personal">
                    {t("personal")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={16}>
              <Form.Item
                name="title"
                label={t("activity_title")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_event_title"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item name="image" label={t("event_image")}>
                <ImgCrop
                  modalWidth={1000}
                  minZoom={0}
                  modalTitle={t("crop_image")}
                  modalOk={t("add")}
                  modalCancel={t("close")}
                  aspect={1}
                  cropperProps={{
                    restrictPosition: false,
                  }}
                >
                  <Upload
                    {...imageUploadProps}
                    customRequest={dummyRequest}
                    beforeUpload={beforeImageUpload}
                  >
                    <Button icon={<UploadOutlined />}>{t("upload")}</Button>
                  </Upload>
                </ImgCrop>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={16}>
              <Form.Item
                name="location"
                label={t("location")}
                rules={[
                  {
                    required: true,
                    message: t("form.enter_event_location"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item name="private" label={t("private")}>
                <Switch
                  checked={isPrivate}
                  onChange={setIsPrivate}
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col lg={12}>
              <Form.Item
                name="startDate"
                label={t("start_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_start_date"),
                  },
                ]}
              >
                <DatePicker
                  className={"w-100"}
                  locale={locale}
                  format={"DD-MM-YYYY HH:mm"}
                  placeholder={t("event_start_placeholder")}
                  onChange={(date) => {
                    setStartDate(date);
                    const suggestedEndDate = dayjs(date + 1000 * 60 * 60 * 2);
                    createEventForm.setFieldsValue({
                      endDate: suggestedEndDate,
                    });
                  }}
                  disabledDate={(current) => {
                    return current < dayjs().startOf("day");
                  }}
                  showToday={false}
                  minuteStep={5}
                  inputReadOnly={!isMobile}
                  showTime={!isMobile}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                name="endDate"
                label={t("end_date")}
                rules={[
                  {
                    required: true,
                    message: t("form.choose_end_date"),
                  },
                ]}
              >
                <DatePicker
                  className={"w-100"}
                  locale={locale}
                  format={"DD-MM-YYYY HH:mm"}
                  placeholder={t("event_end_placeholder")}
                  disabledDate={(current) => {
                    return getDisabledDatesForEndDateEvent(startDate, current);
                  }}
                  showToday={false}
                  minuteStep={5}
                  inputReadOnly={!isMobile}
                  showTime={!isMobile}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="description"
                label={t("description")}
                rules={[
                  {
                    message: t("form.enter_event_description"),
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/*Edit event modal*/}
      {!!currentEvent && (
        <StyledModal
          title={editing ? t("edit_activity") : currentEvent.title}
          open={showEventModalVisible}
          onCancel={closeEditModal}
          onOk={() => (editing ? handleEditEvent() : openEditModal())}
          okText={t(editing ? "save" : "edit_event")}
          cancelText={t("close")}
          footer={[
            <div
              style={{
                display: "flex",
                justifyContent: editing ? "space-between" : "end",
                width: "100%",
              }}
            >
              {editing && (
                <Popconfirm
                  placement="bottom"
                  title={t("confirm_delete_activity_title")}
                  description={t("confirm_delete_event_text")}
                  onConfirm={handleDeleteEvent}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <Button danger>{t("delete")}</Button>
                </Popconfirm>
              )}
              <div>
                <Button onClick={closeEditModal}>{t("close")}</Button>
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={() =>
                    editing ? handleEditEvent() : openEditModal()
                  }
                >
                  {t(editing ? "save" : "edit_event")}
                </Button>
              </div>
            </div>,
          ]}
        >
          {!!currentEvent &&
            (editing ? (
              <Form layout="vertical" form={editEventForm}>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="type"
                      label={t("activity_type")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_type"),
                        },
                      ]}
                    >
                      <Select placeholder={t("form.select_event_type")}>
                        <Select.Option value="meeting">
                          {t("meeting")}
                        </Select.Option>
                        <Select.Option value="event">
                          {t("event")}
                        </Select.Option>
                        <Select.Option value="announcement">
                          {t("announcement")}
                        </Select.Option>
                        <Select.Option value="personally">
                          {t("personally")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="title"
                      label={t("activity_title")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_title"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="image" label={t("event_image")}>
                      <ImgCrop
                        modalWidth={1000}
                        minZoom={0}
                        modalTitle={t("crop_image")}
                        modalOk={t("add")}
                        modalCancel={t("close")}
                        aspect={1}
                        cropperProps={{
                          restrictPosition: false,
                        }}
                      >
                        <Upload
                          {...imageUploadProps}
                          customRequest={dummyRequest}
                          beforeUpload={beforeImageUpload}
                        >
                          <Button icon={<UploadOutlined />}>
                            {t("upload")}
                          </Button>
                        </Upload>
                      </ImgCrop>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={16}>
                    <Form.Item
                      name="location"
                      label={t("location")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_event_location"),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item name="private" label={t("private")}>
                      <Switch
                        checked={isPrivate}
                        onChange={setIsPrivate}
                        checkedChildren={t("yes")}
                        unCheckedChildren={t("no")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col lg={12}>
                    <Form.Item
                      name="startDate"
                      label={t("start_date")}
                      rules={[
                        {
                          required: true,
                          message: t("form.choose_start_date"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY HH:mm"}
                        placeholder={t("event_start_placeholder")}
                        onChange={(date) => {
                          setStartDate(date);
                          const suggestedEndDate = dayjs(
                            date + 1000 * 60 * 60 * 2
                          );
                          editEventForm.setFieldsValue({
                            endDate: suggestedEndDate,
                          });
                        }}
                        disabledDate={(current) => {
                          return current < dayjs().startOf("day");
                        }}
                        showToday={false}
                        minuteStep={5}
                        inputReadOnly={!isMobile}
                        showTime={!isMobile}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item
                      name="endDate"
                      label={t("end_date")}
                      rules={[
                        {
                          required: true,
                          message: t("form.choose_end_date"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY HH:mm"}
                        placeholder={t("event_end_placeholder")}
                        disabledDate={(current) => {
                          return getDisabledDatesForEndDateEvent(
                            startDate,
                            current
                          );
                        }}
                        showToday={false}
                        minuteStep={5}
                        inputReadOnly={!isMobile}
                        showTime={!isMobile}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label={t("description")}
                      rules={[
                        {
                          message: t("form.enter_event_description"),
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ) : (
              <>
                <Row>
                  <Col>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-calendar me-1"></i>
                      {t(currentEvent.type)}
                    </span>
                    {!!currentEvent.createdBy && (
                      <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                        <i className="ti-user me-1"></i>
                        {t("created_by")}{" "}
                        <Link
                          to={userProfileRaw + currentEvent.createdBy}
                          style={{
                            display: "contents",
                            color: colors.primaryLight,
                          }}
                        >
                          {
                            users.find(
                              (user) => user.id === currentEvent.createdBy
                            ).firstName
                          }{" "}
                          {
                            users.find(
                              (user) => user.id === currentEvent.createdBy
                            ).lastName
                          }
                        </Link>
                      </span>
                    )}
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-time me-1"></i>
                      {dayjs
                        .unix(currentEvent.startDate)
                        .format("DD MMM HH:mm")}
                      {` ${t("until").toLowerCase()} `}
                      {dayjs
                        .unix(currentEvent.startDate)
                        .isSame(dayjs.unix(currentEvent.endDate), "day")
                        ? dayjs.unix(currentEvent.endDate).format("HH:mm")
                        : dayjs
                            .unix(currentEvent.endDate)
                            .format("DD MMM HH:mm")}
                    </span>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-location-pin me-1"></i>
                      {currentEvent.location}
                    </span>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      {currentEvent.private ? (
                        <>
                          <i className="ti-lock me-1"></i>
                          {t("private")}
                        </>
                      ) : (
                        <>
                          <i className="ti-unlock me-1"></i>
                          {t("public")}
                        </>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row>
                  {!!currentEvent.description && (
                    <Col lg={24}>
                      <ReactMarkdown>{currentEvent.description}</ReactMarkdown>
                    </Col>
                  )}
                  {!!currentEvent.thumbnail && (
                    <Col lg={24} style={{ marginInline: "auto" }}>
                      <img
                        src={currentEvent.thumbnail}
                        alt={currentEvent.title}
                        className="w-100"
                      />
                    </Col>
                  )}
                </Row>
              </>
            ))}
        </StyledModal>
      )}
    </DashboardLayout>
  );
}

const AgendaWrapper = styled.section`
  @media ${device.sm} {
    margin: 2rem 2rem 0;
  }

  @media ${device.lg} {
    margin: 2rem 2rem 4rem;
  }
`;

const MobileEventsList = styled(DashboardCard)`
  position: relative;
  padding: 0;
  border: none;
  border-radius: 0;
  width: unset;
  height: calc(
    ${({ isYear }) =>
      isYear ? "100vh - 56px - 382px" : "100vh - 56px - 374px"}
  );
  font-weight: 500;
  justify-content: space-between;
  overflow-x: auto;

  .button-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1.25rem 0.5rem 0.5rem;
    background: linear-gradient(
      to top,
      rgba(205, 216, 206, 1) 75%,
      transparent
    );

    button {
      width: 100%;
    }
  }

  p {
    text-align: center;
    margin-block: auto;
  }

  ul {
    overflow: auto;
    padding-bottom: 60px;
  }

  li {
    cursor: pointer;
    padding: 0.3rem 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: space-between;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: ${transparentize(colors.primaryLight, 0.3)};
    }
  }

  @media only screen and (min-width: 481px) {
    height: calc(
      ${({ isYear }) =>
        isYear ? "100vh - 56px - 350px" : "100vh - 56px - 342px"}
    );
  }

  @media only screen and ${device.sm} {
    height: calc(
      ${({ isYear }) =>
        isYear ? "100vh - 56px - 351px - 4rem" : "100vh - 56px - 343px - 4rem"}
    );
    min-height: 11rem;
    margin-inline: 2rem;
    border-inline: 1px solid ${colors.bg_green};
    border-bottom: 1px solid ${colors.bg_green};
    border-bottom-left-radius: ${styles.card_border_radius};
    border-bottom-right-radius: ${styles.card_border_radius};
  }
`;

const StyledCalendar = styled(Calendar)`
  padding: 1rem;
  background-color: ${transparentize("#ffffff", 0.6)};
  border-bottom: 1px solid ${colors.bg_green};
  border-radius: unset;

  .ant-picker-calendar-header {
    padding-top: 0;
  }

  .ant-picker-panel {
    background-color: transparent !important;
  }

  @media only screen and ${device.maxLg} {
    .ant-picker-cell:not(.ant-picker-cell-selected, .ant-picker-cell-today)
      > .ant-picker-cell-inner:has(li)::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 4px;
      border: 1px solid ${colors.primaryLight};
    }
  }

  @media only screen and ${device.sm} {
    border-top-left-radius: ${styles.card_border_radius};
    border-top-right-radius: ${styles.card_border_radius};
    border: 1px solid ${colors.bg_green};
  }

  @media only screen and ${device.lg} {
    border-radius: ${styles.card_border_radius};
  }
`;

const Events = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
  }

  .date {
    margin-left: auto;
    white-space: nowrap;
  }

  .ant-badge-status {
    //width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-badge-status-text {
    font-weight: 500;
    border-bottom: 0.2px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom: 0.2px solid #000000;
    }
  }

  @media ${device.maxLg} {
    li {
      display: none;
    }
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-title {
    padding-right: 1rem;
  }

  .ant-modal-body {
    display: grid;
    gap: 1rem;
  }
`;
