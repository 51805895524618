import React from "react";
import styled from "styled-components";
import { device } from "../utils/breakpoints";

const StyledStyleElement = styled.div`
  position: absolute;
  bottom: ${({ bottom }) => `${bottom}vh`};
  margin-left: auto;
  margin-right: auto;
  ${({ left, right }) => (left ? "left: -12vw" : `right: ${right}vw`)};

  img {
    z-index: 0;
    max-height: 70vh;
    max-width: 70vw;
    opacity: 0.15;
    @media ${device.lg} {
      opacity: 0.35;
      max-width: 100vw;
    }
  }
`;

const StyleElement = ({
  src,
  alt = "",
  left = false,
  bottom = -10,
  right = -14,
  ...rest
}) => {
  return (
    <StyledStyleElement left={left} right={right} bottom={bottom} {...rest}>
      <img src={src} alt={alt} />
    </StyledStyleElement>
  );
};

export default StyleElement;
