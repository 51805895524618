import React from "react";
import { useTranslation } from "react-i18next";
import LinkButton from "../LinkButton";
import { about } from "../../urls";
import {
  BannerDescription,
  BannerLead,
  BannerTitle,
} from "../../styles/sharedStyles";
import data from "../../data/grid-mosaic.json";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";

const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <div className="section position-relative" id="feature">
      <div className="container">
        <div className="row justify-content-center">
          <MosaicGrid className="col-lg-6">
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item.src} alt={item.alt} />
                </div>
              );
            })}
          </MosaicGrid>
          <div className="col-lg-5 offset-lg-1 align-self-center">
            <BannerTitle>
              <b>{t("home_about_title")}</b>
            </BannerTitle>
            <BannerDescription>{t("home_about_description")}</BannerDescription>
            <BannerLead>{t("home_about_lead")}</BannerLead>
            <LinkButton to={about} type={"outlined"}>
              {t("read_more_oostwest")}
            </LinkButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const MosaicGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(18, 1fr);
  gap: 1rem;
  max-width: 600px;

  div {
    width: 100%;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 10%);
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  div:nth-child(1) {
    grid-column: 1 / 8;
    grid-row: 2 / 9;
  }

  div:nth-child(2) {
    grid-column: 8 / span 5;
    grid-row: 1 / 10;
  }

  div:nth-child(3) {
    grid-column: 3 / span 5;
    grid-row: 9 / 18;
  }

  div:nth-child(4) {
    grid-column: 8 / span 5;
    grid-row: 10 / -1;
  }

  @media ${device.xl} {
    max-width: 700px;
    padding-inline: 2.5rem;

    gap: 1.5rem;
  }
`;

export default AboutSection;
