import React from "react";
import styled from "styled-components";
import { device } from "../../utils/breakpoints";
import { colors } from "../../utils/theme";

const FullWidthImageAndTextBanner = ({ src, alt, title, description }) => {
  return (
    <div className="section">
      <Wrapper className="position-relative">
        <div className="row">
          <div className="col-lg-6 align-self-center d-flex justify-content-center">
            <div className={"col-lg-10 p-3"}>
              <BannerTitle>
                <b>{title}</b>
              </BannerTitle>
              <BannerDescription>{description}</BannerDescription>
            </div>
          </div>
          <div className="col-lg-6">
            <Image src={src} alt={alt} className="img-fluid" />
          </div>
          <ButterflyStyleElement
            src={"assets/images/illustrations/illustratie-07.svg"}
          />
        </div>
      </Wrapper>
    </div>
  );
};

const Image = styled.img`
  width: fill-available;
  max-height: 80vh;
  object-fit: contain;
  height: -webkit-fill-available;
`;

const ButterflyStyleElement = styled.img`
  position: absolute;
  width: 180px;
  right: 55vw;
  bottom: 60vh;
  z-index: 0;

  @media ${device.maxXl} {
    bottom: -20vh;
  }

  @media ${device.maxLg} {
    display: none;
  }
`;

const BannerDescription = styled.p`
  font-size: 18px;
  line-height: 26px;
  @media ${device.lg} {
    font-size: 26px;
    line-height: 34px;
  }
`;

const BannerTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  color: ${colors.header};
  margin-bottom: 0.7rem;

  @media ${device.lg} {
    font-size: 34px;
  }
`;

const Wrapper = styled.div`
  background-color: ${colors.light};

  @media ${device.maxLg} {
    padding-top: 2rem;
  }
`;

export default FullWidthImageAndTextBanner;
