import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { home } from "../urls";
import { device } from "../utils/breakpoints";

const Logo = ({ header = false, footer = false, to = home, ...props }) => {
  const closeMenu = () => {
    if (window.location.pathname !== "/")
      document.body.classList.remove("fixed_body");
  };

  return (
    <Link to={to}>
      <Image
        src={"/assets/images/logo/logo-512.png"}
        alt={"Oostwest Logo"}
        header={header}
        footer={footer}
        {...props}
        onClick={closeMenu}
      />
    </Link>
  );
};

const Image = styled.img`
  width: ${({ header, footer }) =>
    footer ? `80px` : header ? `60px` : `256px`};

  @media ${device.lg} {
    width: ${({ header, footer }) =>
      footer ? `120px` : header ? `100px` : `256px`};
  }
`;

export default Logo;
