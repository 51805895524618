import React from "react";
import HomeLayout from "../layouts/HomeLayout";
import { useTranslation } from "react-i18next";
import InfoText from "../components/Banners/InfoText";
import termsData from "../data/info/terms-conditions-data.json";

const TermsConditions = () => {
  const { i18n } = useTranslation();
  const DATA = termsData[i18n.language];
  return (
    <HomeLayout>
      <InfoText data={DATA} />
    </HomeLayout>
  );
};

export default TermsConditions;
