import {
  CLOSE_ALL_MODALS,
  TOGGLE_HIRE_SPACE_MODAL,
} from "../actions/modalActions";

const initState = [];

const modalReducer = (state = initState, action) => {
  const modals = state;

  if (action.type === TOGGLE_HIRE_SPACE_MODAL) {
    if (!modals) return { hireSpace: true };
    else if (!modals.hireSpace) return { ...modals, hireSpace: true };
    else return { ...modals, hireSpace: false };
  }

  if (action.type === CLOSE_ALL_MODALS) {
    return null;
  }

  return state;
};

export default modalReducer;
