import React from "react";
import { DashboardFeedCard } from "../styles/dashboardStyles";
import styled from "styled-components";
import { colors } from "../utils/theme";

const Load = () => {
  return (
    <DashboardFeedCard>
      <DotsContainer>
        <Dots>
          <span />
          <span />
          <span />
        </Dots>
      </DotsContainer>
    </DashboardFeedCard>
  );
};

const DotsContainer = styled.div`
  width: 100%;
  height: 10px;
  display: grid;
  place-content: center;
`;

const Dots = styled.div`
  position: relative;
  display: flex;
  gap: 0.5rem;

  span {
    display: block;
    width: 8px;
    height: 8px;
    background-color: ${colors.secondary};
    border-radius: 50%;

    &:nth-child(1) {
      transform: translateY(0);
      animation: moveUpDown 1.5s linear infinite;
    }
    &:nth-child(2) {
      transform: translateY(0);
      animation: moveUpDown 1.5s linear 0.2s infinite;
    }
    &:nth-child(3) {
      transform: translateY(0);
      animation: moveUpDown 1.5s linear 0.4s infinite;
    }
  }

  @keyframes moveUpDown {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(-8px);
    }
    50% {
      transform: translateY(0);
    }
  }
`;
export default Load;
