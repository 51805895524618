import { sendContactMail, sendHireSpaceConfirmation } from "../urls";

export const sendHireSpaceConfirmationEmail = (object) => {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    fetch(sendHireSpaceConfirmation, requestOptions)
      .then(() => resolve())
      .catch(() => reject());
  });
};

export const sendContactEmail = (object) => {
  return new Promise(function (resolve, reject) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    fetch(sendContactMail, requestOptions)
      .then(() => resolve())
      .catch(() => reject());
  });
};
