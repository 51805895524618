import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextContainer from "../../../components/Subscription/TextContainer";
import { home } from "../../../urls";

const Failed = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <TextContainer>
        <h4>{t("subscribe_failed_title")}</h4>
        <p>{t("subscribe_failed_description")}</p>
        <Link to={home}>{t("back_home")}</Link>
      </TextContainer>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export default Failed;
