export const TOGGLE_HIRE_SPACE_MODAL = "TOGGLE_HIRE_SPACE_MODAL";
export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";

export const toggleHireSpaceModal = () => {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_HIRE_SPACE_MODAL,
    });
  };
};

export const closeAllModals = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ALL_MODALS,
    });
  };
};
