import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import modalReducer from "./modalReducer";

const rootReducer = combineReducers({
  firebaseReducer,
  firestoreReducer,
  modalData: modalReducer,
});

export default rootReducer;
