import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { device } from "../../utils/breakpoints";
import { Col, Form, Input, message } from "antd";
import StyledButton from "../StyledButton";
import { transparentize } from "../../utils/helperFn";
import { useFirestore } from "react-redux-firebase";
import { FIRESTORE_NEWSLETTER_EMAILS_TABLE } from "../../constants/FirebaseConstants";

const EnterEmailBanner = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const firestore = useFirestore();
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);
      // Save application to firestore
      firestore
        .collection(FIRESTORE_NEWSLETTER_EMAILS_TABLE)
        .add({ email: values.email })
        .then(() => {
          setSubmitted(true);
          setIsLoading(false);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          message.error(t("errors.generic"));
          setIsLoading(false);
        });
    });
  };

  return (
    <div className={"section"}>
      <BannerWrapper>
        <Col xs={24} lg={12} className={"p-3 p-lg-5 align-self-center"}>
          <h2 className="title-text2 mb-4">
            <b>{t("home_image_text_title")}</b>
          </h2>
          <p>{t("home_image_text_description")}</p>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="email"
              label={t("email")}
              rules={[
                {
                  required: true,
                  message: t("form.enter_email"),
                },
                {
                  type: "email",
                  message: t("form.enter_valid_email"),
                },
              ]}
            >
              <StyledInput
                size={"large"}
                placeholder={t("email_input_placeholder_banner")}
              />
            </Form.Item>
            {!submitted ? (
              <StyledButton
                type="primary"
                htmlType={"submit"}
                loading={isLoading}
              >
                {t("home_image_text_button")}
              </StyledButton>
            ) : (
              <ConfirmMessage>
                {t("newsletter_signup_confirmation")}
              </ConfirmMessage>
            )}
          </Form>
        </Col>
        <StyledCol xs={0} lg={12}>
          <Image src={"/assets/images/crew/ow-crew-5.webp"} />
        </StyledCol>
      </BannerWrapper>
    </div>
  );
};

const ConfirmMessage = styled.p`
  color: ${colors.header};
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  background-color: transparent !important;
  color: ${colors.secondary};
  border: 1px solid ${colors.secondary};
  width: 100%;

  &:focus,
  &:hover {
    border-color: ${colors.secondary};
    box-shadow: 0 0 2px 2px ${transparentize(colors.secondary, 0.2)};
  }
`;

const StyledCol = styled(Col)`
  padding: 0;
  z-index: 1;
`;

const Image = styled.img`
  width: inherit;
  height: -webkit-fill-available;
  object-fit: cover;

  @media ${device.maxLg} {
  }

  @media ${device.lg} {
    width: fill-available;
  }
`;

const BannerWrapper = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.light};
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 10%);
  max-height: 80vh;
  overflow: hidden;

  @media ${device.maxLg} {
    margin-top: 4rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  h2 {
    font-size: 22px;
    font-weight: 700;
    color: ${colors.header};
    margin-bottom: 0.5rem;
    margin-top: 0;
    text-transform: uppercase;

    @media ${device.lg} {
      font-size: 34px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2rem;

    @media ${device.lg} {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export default EnterEmailBanner;
