import { message, Upload } from "antd";
import i18n from "i18next";

export const beforeImageUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error(i18n.t("errors.only_png_jpg"));
    return Upload.LIST_IGNORE;
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error(i18n.t("errors.image_max_size"));
    return Upload.LIST_IGNORE;
  }
  return isJpgOrPng && isLt2M;
};

export const beforeAudioUpload = (file) => {
  // Only accept mp3 and wav
  const isAudioFile = ["audio/mpeg", "audio/wav"].includes(file.type);
  if (!isAudioFile) {
    message.error(i18n.t("errors.only_mpeg"));
    return Upload.LIST_IGNORE;
  }
  return isAudioFile;
};

export const dummyRequest = ({ file, onSuccess }) => {
  onSuccess("ok");
};
