export const HIRE_REQUEST_STATUS_OPEN = "open";
export const HIRE_REQUEST_STATUS_ACCEPTED = "accepted";
export const HIRE_REQUEST_STATUS_DETAILS_SUBMITTED =
  "company_details_submitted";
export const HIRE_REQUEST_STATUS_SIGNED = "signed";
export const HIRE_REQUEST_STATUS_COMPLETED = "completed";
export const HIRE_REQUEST_STATUS_REJECTED = "rejected";

// Used for changing request status
export const HIRE_REQUEST_STATUSSES = [
  HIRE_REQUEST_STATUS_OPEN,
  HIRE_REQUEST_STATUS_ACCEPTED,
  HIRE_REQUEST_STATUS_SIGNED,
  HIRE_REQUEST_STATUS_COMPLETED,
];

export const PAYMENT_METHOD_DIRECT_DEBIT = "direct_debit";
export const PAYMENT_METHOD_INVOICE = "invoice";
