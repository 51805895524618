import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isEmpty, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { login } from "./urls";

const PrivateRoute = () => {
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const isAuthenticated = isLoaded(auth) && !isEmpty(auth);

  return isAuthenticated ? <Outlet /> : <Navigate to={login} />;
};

export default PrivateRoute;
