import React, { Fragment, useEffect, useState } from "react";
import Logo from "../components/Logo";
import { dashboardHome, sendLoginLink } from "../urls";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, notification, Spin } from "antd";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";
import axios from "axios";

const Login = () => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [loginEmailSent, setLoginEmailSent] = useState(false);

  useEffect(() => {
    if (!auth.isEmpty && auth.isLoaded) {
      notification.success({
        message: t(
          !!profile && !!profile.fullName
            ? "notifications.welcome_back_name"
            : "notifications.welcome_back",
          {
            name:
              !!profile && !!profile.fullName
                ? profile.fullName.split(" ")[0]
                : null,
          },
        ),
      });
      navigate(dashboardHome);
    }
  }, [auth]);

  useEffect(() => {
    setIsVerifying(true);
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt(t("confirm_email_prompt"));
      }
      firebase
        .auth()
        .signInWithEmailLink(email.toLowerCase().trim(), window.location.href)
        .then((result) => {
          window.localStorage.removeItem("emailForSignIn");
          navigate(dashboardHome);
        })
        .catch((error) => {
          console.log(error);
        });
    } else setIsVerifying(false);
  }, []);

  const handleLogin = async () => {
    form.validateFields().then(async (values) => {
      setIsLoading(true);
      const email = values.email.toLowerCase().trim();
      // Send request to backend to create login link. If no user exists, show error message
      const params = new URLSearchParams([["email", email]]);
      const response = await axios
        .get(sendLoginLink, { params })
        .catch((err) => {
          setIsLoading(false);
          form.resetFields();
          notification.error({
            message: t("errors.login_no_user_title"),
            description: t("errors.login_no_user_description"),
            duration: 0,
          });
        });
      if (!!response && response.status === 200) {
        window.localStorage.setItem("emailForSignIn", email);
        setLoginEmailSent(true);
        setIsLoading(false);
      }
    });
  };

  return (
    <Fragment>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100 justify-content-between">
            <Logo header />
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-5 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{
              backgroundImage: 'url("/assets/images/about/bram-speak.webp")',
            }}
          />
          <div className="col-xl-7 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                {isVerifying ? (
                  <Spin size={"large"} />
                ) : loginEmailSent ? (
                  <>
                    <h2 style={{ fontWeight: "bold" }}>
                      {t("login_link_sent")}
                    </h2>
                    <p>{t("login_link_sent_description")}</p>
                  </>
                ) : (
                  <>
                    <h2 className="fw-700 display1-size display2-md-size">
                      {t("login_form_title")}
                    </h2>
                    <p className={"mb-3"}>{t("login_form_description")}</p>
                    <Form form={form} layout={"vertical"}>
                      <Form.Item
                        name="email"
                        label={t("email")}
                        rules={[
                          {
                            required: true,
                            message: t("form.enter_email"),
                          },
                        ]}
                      >
                        <Input onPressEnter={handleLogin} />
                      </Form.Item>
                      <div className="col-sm-12 p-0 text-left">
                        <div className="form-group mb-1">
                          <Button
                            type="primary"
                            block
                            loading={isLoading}
                            onClick={handleLogin}
                          >
                            {t("login")}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
