import React, { useEffect, useState } from "react";
import { DashboardCard } from "../styles/dashboardStyles";
import { events } from "../urls";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, styles } from "../utils/theme";
import dayjs from "dayjs";
import { filter } from "lodash";
const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
dayjs.extend(isSameOrAfter);

export default function EventsAside() {
  const { t } = useTranslation();
  const allEvents = useSelector(
    (state) => state.firestoreReducer.ordered.events,
  );
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const nextEvents = filter(allEvents, (item) => {
      return (
        dayjs.unix(item.endDate).isSameOrAfter(dayjs()) && item.type === "event"
      );
    });
    setUpcomingEvents(nextEvents);
  }, [allEvents]);

  return (
    <StyledDashboardCard>
      <TitleContainer>
        <h4 className="fw-700 mb-0 font-xssss text-grey-900">{t("events")}</h4>
        <Link to={events} className="fw-600 ms-auto font-xssss">
          {t("see_all")}
        </Link>
      </TitleContainer>
      <EventsGrid>
        {!!upcomingEvents &&
          upcomingEvents.map((event, index) => {
            const startDateIsToday = dayjs
              .unix(event.startDate)
              .isSame(dayjs(), "day");
            const startDateIsThisWeek = dayjs
              .unix(event.startDate)
              .isSame(dayjs(), "week");
            return (
              index < 5 && (
                <Event key={event.id}>
                  <DateTile
                    isToday={startDateIsToday}
                    isThisWeek={startDateIsThisWeek}
                  >
                    <h4 className="fw-700 ls-3 mb-0">
                      <span className="ls-3 d-block font-xsss text-white fw-500">
                        {dayjs.unix(event.startDate).format("DD")}
                      </span>
                      <span className={"text-uppercase"}>
                        {dayjs.unix(event.startDate).format("MMM")}
                      </span>
                    </h4>
                  </DateTile>
                  <EventContent>
                    <h2 className="fw-700 lh-3 font-xss">{event.title}</h2>
                    <span className="d-flex font-xssss fw-500 mt-2 lh-3 text-grey-500">
                      <i className="ti-location-pin me-1"></i>
                      {event.location}
                    </span>
                  </EventContent>
                </Event>
              )
            );
          })}
      </EventsGrid>
    </StyledDashboardCard>
  );
}

const StyledDashboardCard = styled(DashboardCard)`
  margin-bottom: 0;
  a {
    color: ${colors.secondary};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const EventsGrid = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const Event = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DateTile = styled.div`
  width: 3.5rem;
  padding: 0.5rem;
  background-color: ${({ isToday, isThisWeek }) =>
    isToday
      ? colors.secondary
      : isThisWeek
      ? colors.secondaryLight
      : colors.primaryLight};
  border-radius: ${styles.btn_border_radius};

  h4:last-child {
    color: ${({ isToday }) => (isToday ? "white" : "initial")};
  }
`;

const EventContent = styled.div``;
