import React, { useEffect, useState } from "react";
import HomeLayout from "../layouts/HomeLayout";
import StyleElement from "../components/StyleElement";
import HireSpaceModal from "../components/Modal/HireSpaceModal";
import { Col, DatePicker, Form, Input, message, Row, Select } from "antd";
import StyledButton from "../components/StyledButton";
import styled from "styled-components";
import { colors } from "../utils/theme";
import { device } from "../utils/breakpoints";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { FIRESTORE_HIRE_REQUESTS_TABLE } from "../constants/FirebaseConstants";
import firebase from "../services/firebase";
import { Loader } from "../components/Loader";
import locale from "antd/es/date-picker/locale/nl_NL";
import * as confirmedAnimation from "../assets/lottie/checkAnimation.json";
import Lottie from "react-lottie-player";
import {
  HIRE_REQUEST_STATUS_DETAILS_SUBMITTED,
  PAYMENT_METHOD_DIRECT_DEBIT,
  PAYMENT_METHOD_INVOICE,
} from "../constants/HireRequestConstants";
import { SPACE_ID_FLEX_WORKSPACE } from "../constants/SpacesConstants";
import dayjs from "dayjs";

const firestore = firebase.firestore();

const AddSubscriptionContactDetails = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [request, setRequest] = useState(null);

  useEffect(() => {
    let requestId = null;
    try {
      requestId = atob(searchParams.get("aanvraag"));
      firestore
        .collection(FIRESTORE_HIRE_REQUESTS_TABLE)
        .doc(requestId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = { ...doc.data(), id: doc.id };
            setRequest(data);
            form.setFieldsValue({
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              phone: data.phone,
              paymentMethod: PAYMENT_METHOD_DIRECT_DEBIT,
            });
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
    } catch (e) {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setSubmitLoading(true);
      values.addition = values.addition || null;
      values.dateOfBirth = values.dateOfBirth.format("DD-MM-YYYY");

      // Check if request is defined
      if (!request) {
        console.error("Request is undefined");
        setSubmitLoading(false);
        message.error(t("notifications.contact_details_not_saved"));
        return;
      }

      // Initialize logs if it's undefined
      let logs = request.logs || {};
      logs.addedDetails = dayjs().unix();

      firestore
        .collection(FIRESTORE_HIRE_REQUESTS_TABLE)
        .doc(request.id)
        .update({
          companyDetails: values,
          status: HIRE_REQUEST_STATUS_DETAILS_SUBMITTED,
          logs: logs,
        })
        .then(() => {
          setSubmitLoading(false);
          setSubmitted(true);
          message.success(t("notifications.contact_details_saved"));
          form.resetFields();
        })
        .catch(() => {
          setSubmitLoading(false);
          message.error(t("notifications.contact_details_not_saved"));
        });
    });
  };

  return (
    <HomeLayout>
      <div
        className={"container"}
        style={
          isLoading || (!request && !isLoading)
            ? {
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }
            : {}
        }
      >
        {!!request && !isLoading ? (
          !!request.companyDetails || submitted ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LottieWrapper>
                <Lottie animationData={confirmedAnimation} play />
              </LottieWrapper>
              <Title>{t("add_subscription_details_done_title")}</Title>
              <SubTitle>
                {t("add_subscription_details_done_description")}
              </SubTitle>
              <Link to={"/"}>{t("back_home")}</Link>
            </div>
          ) : (
            <>
              <Title>
                {t("add_subscription_details_form_title", {
                  firstName: !!request ? request.firstName : "-",
                })}
              </Title>
              <SubTitle>
                {t("add_subscription_details_form_description")}
              </SubTitle>
              <StyledForm
                form={form}
                layout={"vertical"}
                onFinish={handleSubmit}
              >
                <FormSectionTitle>{t("company_details")}</FormSectionTitle>
                <Row gutter={16}>
                  <Col xs={8}>
                    <Form.Item
                      name="companyName"
                      label={t("company_name")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_company_name"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("company_name")} />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      name="chamberOfCommerceNumber"
                      label={t("chamber_of_commerce_number")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_chamber_of_commerce_number"),
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || /^[0-9]*$/.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(t("form.only_numbers_allowed"))
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        size={"large"}
                        placeholder={t("chamber_of_commerce_number")}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      name="companyTaxNumber"
                      label={t("company_tax_number")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_company_tax_number"),
                        },
                      ]}
                    >
                      <Input
                        size={"large"}
                        placeholder={t("company_tax_number")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="firstName"
                      label={t("first_name")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_first_name"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("first_name")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="lastName"
                      label={t("last_name")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_last_name"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("last_name")} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="email"
                      label={t("email")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_email"),
                        },
                        {
                          type: "email",
                          message: t("form.enter_valid_email"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("email")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="phone"
                      label={t("phone")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_phone"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("phone")} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="dateOfBirth"
                      label={t("date_of_birth")}
                      rules={[
                        {
                          required: true,
                          message: t("form.pick_value"),
                        },
                      ]}
                    >
                      <DatePicker
                        className={"w-100"}
                        locale={locale}
                        format={"DD-MM-YYYY"}
                        placeholder={t("choose_date")}
                        showToday={false}
                        inputReadOnly={true}
                        size={"large"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="paymentMethod"
                      label={t("payment_method")}
                      rules={[
                        {
                          required: true,
                          message: t("form.pick_value"),
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        disabled={
                          request.chosenSpace === SPACE_ID_FLEX_WORKSPACE
                        }
                      >
                        <Select.Option value={PAYMENT_METHOD_DIRECT_DEBIT}>
                          {t("direct_debit")}
                        </Select.Option>
                        <Select.Option value={PAYMENT_METHOD_INVOICE}>
                          {t("by_invoice")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <FormSectionTitle style={{ marginTop: "1rem" }}>
                  {t("address_details")}
                </FormSectionTitle>
                <Row gutter={16}>
                  <Col xs={10}>
                    <Form.Item
                      name="street"
                      label={t("street")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("street")} />
                    </Form.Item>
                  </Col>
                  <Col xs={8}>
                    <Form.Item
                      name="houseNumber"
                      label={t("house_number")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                        () => ({
                          validator(_, value) {
                            if (!value || /^[0-9]*$/.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(t("form.only_numbers_allowed"))
                            );
                          },
                        }),
                      ]}
                    >
                      <Input size={"large"} placeholder={t("house_number")} />
                    </Form.Item>
                  </Col>
                  <Col xs={6}>
                    <Form.Item
                      name="addition"
                      label={t("house_number_addition")}
                    >
                      <Input
                        size={"large"}
                        placeholder={t("house_number_addition")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item
                      name="zipCode"
                      label={t("zip_code")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("zip_code")} />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      name="city"
                      label={t("city")}
                      rules={[
                        {
                          required: true,
                          message: t("form.enter_value"),
                        },
                      ]}
                    >
                      <Input size={"large"} placeholder={t("city")} />
                    </Form.Item>
                  </Col>
                </Row>
                <StyledButton loading={submitLoading} htmlType="submit">
                  {t("send")}
                </StyledButton>
              </StyledForm>
            </>
          )
        ) : !isLoading && !request ? (
          <Title>{t("no_request_found")}</Title>
        ) : (
          <Loader />
        )}
      </div>
      <div className={"position-relative"}>
        <StyleElement
          src={"assets/images/illustrations/illustratie-06.svg"}
          alt={""}
          bottom={-25}
          right={75}
        />
      </div>
      <HireSpaceModal />
    </HomeLayout>
  );
};

const LottieWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
`;

const FormSectionTitle = styled.h4`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledForm = styled(Form)`
  position: relative;
  z-index: 1;
  margin-top: 3rem;

  input,
  textarea,
  .ant-picker,
  .ant-select-selector {
    background-color: ${colors.light} !important;
    border: 1px solid #b0b0b0;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.header};
  text-transform: uppercase;

  @media ${device.lg} {
    font-size: 30px;
  }
`;

export default AddSubscriptionContactDetails;
