export const PROFESSION_MUSICIAN = "MUSICIAN";
export const PROFESSION_PRODUCER = "PRODUCER";
export const PROFESSION_PAINTER = "PAINTER";
export const PROFESSION_DJ = "DJ";
export const PROFESSION_DESIGNER = "DESIGNER";
export const PROFESSION_ARTIST = "ARTIST";
export const PROFESSION_OTHER = "OTHER";

export const PROFESSIONS = [
  { id: PROFESSION_MUSICIAN, nl: "Muzikant", en: "Musician" },
  { id: PROFESSION_PRODUCER, nl: "Producer", en: "Producer" },
  { id: PROFESSION_PAINTER, nl: "Schilder", en: "Painter" },
  { id: PROFESSION_DJ, nl: "DJ", en: "DJ" },
  { id: PROFESSION_DESIGNER, nl: "Designer", en: "Designer" },
  { id: PROFESSION_ARTIST, nl: "Artiest", en: "Artist" },
  { id: PROFESSION_ARTIST, nl: "Zanger", en: "Singer" },
  { id: PROFESSION_OTHER, nl: "Anders", en: "Other" },
];
