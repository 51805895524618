import React, { useEffect, useState } from "react";
import ProfileDetail from "../../components/ProfileDetail";
import ProfileCard from "../../components/ProfileCard";
import Load from "../../components/Load";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { find } from "lodash";
import {
  DashboardCard,
  DashboardSection,
  DashboardWrapper,
} from "../../styles/dashboardStyles";
import styled from "styled-components";
import { usersOverview } from "../../urls";
import { useTranslation } from "react-i18next";
import { colors, styles } from "../../utils/theme";

const UserProfile = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const uid = auth.uid;
  const allUsers = useSelector((state) => state.firestoreReducer.ordered.users);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const foundUser = find(allUsers, (item) => item.id === userId);
    if (!!foundUser) {
      setUser(foundUser);
      setLoading(false);
    }
  }, [userId, allUsers]);

  return (
    <DashboardLayout>
      <DashboardWrapper>
        <DashboardSection narrow>
          {!loading ? (
            <>
              <StyledDashboardCard>
                <ProfileCard user={user} />
              </StyledDashboardCard>
              <ProfileDetail user={user} edit={userId === uid} />
              <StyledLink to={usersOverview}>
                <i className="ti-arrow-left font-sm text-white"></i>
                <span className="font-xss text-white fw-500 mb-0">
                  {t("go_back")}
                </span>
              </StyledLink>
            </>
          ) : (
            <Load />
          )}
        </DashboardSection>
      </DashboardWrapper>
    </DashboardLayout>
  );
};

const StyledDashboardCard = styled(DashboardCard)`
  padding: 0;
  margin: 0;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  background-color: ${colors.bg_green};
  width: max-content;
  padding: 0.25rem 1.5rem;
  border-radius: ${styles.btn_border_radius};
  transition: background-color 0.2s ease-out;

  h4 {
    line-height: 1;
  }

  &:hover {
    background-color: ${colors.primaryMedium};
  }
`;

export default UserProfile;
