import _ from "lodash";
import { PROFESSIONS } from "../constants/ProfessionConstants";
import i18n from "i18next";

export const getUserById = (users, uid) => {
  const user = _.find(users, (item) => item.id === uid);
  if (!!user) return user;
  else return null;
};

export const getReadableProfession = (profession) => {
  const foundProfession = _.find(PROFESSIONS, (item) => item.id === profession);
  return foundProfession[i18n.language];
};
