import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

export default function InfoText({ data }) {
  return (
    <Wrapper className={"container"}>
      {!!data.title && <h1>{data.title}</h1>}
      <Text>{data.text}</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 3rem 0 3rem 0;
`;

const Text = styled(ReactMarkdown)`
  p {
    white-space: pre-line;
    margin-bottom: 1rem;
  }

  ul {
    margin-bottom: 1rem;

    li {
      list-style: circle;

      p {
        margin-bottom: 0;
      }
    }
  }
`;
