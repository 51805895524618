import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { colors } from "../../utils/theme";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { device } from "../../utils/breakpoints";

SwiperCore.use([Navigation, Pagination]);

const NewsBanner = ({ data, light = false }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <div className="section">
      <div className="container">
        <div className={"d-flex text-center justify-content-center"}>
          <div className={"col-lg-9"}>
            <BannerTitle light={light ? 1 : 0}>
              {t("news_banner_title")}
            </BannerTitle>
            <BannerText light={light ? 1 : 0}>
              {t("news_banner_description")}
            </BannerText>
          </div>
        </div>
        <SliderWrapper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{ clickable: true, dynamicBullets: true }}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
          }}
        >
          {data[lang].map((item, index) => {
            return (
              <SwiperSlide
                key={index}
                onClick={() => {
                  // slideTo(index);
                }}
              >
                <a href={item.url} target={"_blank"} rel={"noreferrer"}>
                  <SmallItemWrapper

                  // active={currentSlide === index}
                  >
                    <Image src={item.image} alt={item.image_alt} />
                  </SmallItemWrapper>
                </a>
              </SwiperSlide>
            );
          })}
        </SliderWrapper>
      </div>
    </div>
  );
};

const Image = styled.img`
  border-radius: 6px;
  background-color: ${colors.primary};
  transition: transform 0.5s, filter 1s ease-in-out;
  filter: grayscale(100%);
  width: fill-available;
`;

const SmallItemWrapper = styled.div`
  border-radius: 4px;
  text-align: center;
  transition: 0.2s all ease;
  overflow: hidden;
  margin: 0.65rem 0;
  box-shadow: 0 0 6px 2px rgb(0 0 0 / 10%);

  &:hover {
    img {
      filter: grayscale(0);
      transform: scale(1.05);
    }
  }

  @media ${device.lg} {
    margin-bottom: 1rem;
  }
`;

const SliderWrapper = styled(Swiper)`
  padding: 1rem 0.5rem;

  .swiper-pagination {
    margin-top: 2rem;
  }

  .swiper-pagination-bullets {
    bottom: 4px;
  }

  .swiper-pagination-bullet {
    width: 0.8rem;
    height: 0.8rem;
  }

  .swiper-pagination-bullet-active {
    background: ${colors.secondary};
  }
`;

const BannerTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ light }) => (light === 1 ? colors.light : colors.header)};
  margin-bottom: 0.25rem;
  margin-top: 0;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 10%);

  @media ${device.lg} {
    font-size: 28px;
    line-height: 36px;
  }
`;

const BannerText = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  color: ${({ light }) => (light === 1 ? colors.light : colors.text)};
  margin-bottom: 1.2rem;
  margin-top: 0;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 10%);

  @media ${device.lg} {
    font-size: 25px;
    line-height: 38px;
  }
`;

export default NewsBanner;
