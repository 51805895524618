import React, { useState } from "react";
import { Col, Form, Input, message, Row } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "../../utils/theme";
import { device } from "../../utils/breakpoints";
import { Container } from "react-bootstrap";
import StyledButton from "../StyledButton";
import {
  COMPANY_ADDRESS_LINE,
  COMPANY_CITY,
  COMPANY_EMAIL,
  COMPANY_NAME,
  COMPANY_ZIP_CODE,
  SOCIAL_MEDIA_INSTAGRAM_URL,
  SOCIAL_MEDIA_LINKEDIN_URL,
  SOCIAL_MEDIA_TIKTOK_URL,
} from "../../constants/CompanyConstants";
import { transparentize } from "../../utils/helperFn";
import { sendContactEmail } from "../../functions/Api";
import TikTokSvg from "../../assets/icons/tik-tok.svg";

const ContactForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setIsLoading(true);
      sendContactEmail(values)
        .then(() => {
          setIsLoading(false);
          message.success(t("notifications.contact_mail_sent"));
          form.resetFields();
        })
        .catch(() => {
          setIsLoading(false);
          message.error(t("notifications.contact_mail_not_sent"));
        });
    });
  };

  return (
    <StyledContainer>
      <Row gutter={32}>
        <Col lg={14}>
          <Title>{t("contact_form_title")}</Title>
          <SubTitle>{t("contact_form_subtitle")}</SubTitle>
          <StyledForm form={form} layout={"vertical"} onFinish={handleSubmit}>
            <Row gutter={16}>
              <Col xs={12}>
                <Form.Item
                  name="firstName"
                  label={t("first_name")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_first_name"),
                    },
                  ]}
                >
                  <Input size={"large"} />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  name="lastName"
                  label={t("last_name")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_last_name"),
                    },
                  ]}
                >
                  <Input size={"large"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="email"
                  label={t("email")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_email"),
                    },
                    {
                      type: "email",
                      message: t("form.enter_valid_email"),
                    },
                  ]}
                >
                  <Input size={"large"} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24}>
                <Form.Item
                  name="question"
                  label={t("question_label")}
                  rules={[
                    {
                      required: true,
                      message: t("form.enter_question"),
                    },
                  ]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            <StyledButton loading={isLoading} htmlType="submit">
              {t("send")}
            </StyledButton>
          </StyledForm>
        </Col>
        <Col xs={24} lg={10}>
          <ContactCard>
            <div>
              <CardTitle>{COMPANY_NAME}</CardTitle>
              <h5>{COMPANY_ADDRESS_LINE}</h5>
              <h5>
                {COMPANY_ZIP_CODE}, {COMPANY_CITY}
              </h5>
            </div>
            <div>
              <h4>{t("mail_to")}</h4>
              <a href={"mailto:" + COMPANY_EMAIL}>{COMPANY_EMAIL}</a>
            </div>
            <Row>
              <SocialIcon
                className={"mr-2"}
                href={SOCIAL_MEDIA_INSTAGRAM_URL}
                target={"_blank"}
              >
                <i className="feather-instagram" />
              </SocialIcon>
              <SocialIcon href={SOCIAL_MEDIA_LINKEDIN_URL} target={"_blank"}>
                <i className="feather-linkedin" />
              </SocialIcon>
              <SocialIcon href={SOCIAL_MEDIA_TIKTOK_URL} target={"_blank"}>
                <TikTokIcon src={TikTokSvg} alt="TikTok" />
              </SocialIcon>
            </Row>
          </ContactCard>
        </Col>
      </Row>
    </StyledContainer>
  );
};

const TikTokIcon = styled.img`
  width: 28px;
  height: 28px;
  color: ${colors.header};
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));

  @media ${device.lg} {
    width: 34px;
    height: 34px;
  }
`;

const SocialIcon = styled.a`
  margin: 0.5rem;
  cursor: pointer;
  z-index: 10;

  i {
    font-size: 28px;
    color: ${colors.header};
    text-shadow: 0 2px 4px rgba(0, 0, 0, 25%);

    @media ${device.lg} {
      font-size: 34px;
    }
  }
`;

const CardTitle = styled.h4`
  margin-top: 3rem;
  padding-bottom: 1rem;
`;

const ContactCard = styled.div`
  position: relative;
  z-index: 10;
  background-color: ${colors.light};
  background-image: url("assets/images/illustrations/illustratie-18-02.svg");
  background-position: 5% 60%;
  padding: 3rem 2rem;
  box-shadow: 0 0 10px 4px ${transparentize(colors.primary, 0.2)};
  border-radius: 8px;

  @media ${device.maxLg} {
    margin-top: 3rem;
  }

  h4 {
    font-size: 28px;
    color: ${colors.header};
    margin-bottom: 2px;
  }

  h5 {
    font-size: 16px;
    color: ${colors.header};
  }

  a {
    font-size: 16px;
    color: ${colors.secondary};
  }

  div {
    margin-bottom: 1rem;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding-top: 4rem;
  padding-bottom: 5rem;

  @media ${device.lg} {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
`;

const StyledForm = styled(Form)`
  position: relative;
  z-index: 1;

  input,
  textarea {
    background-color: ${colors.light} !important;
    border: 1px solid #b0b0b0;
  }
`;

const SubTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-line;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.header};
  text-transform: uppercase;

  @media ${device.lg} {
    font-size: 30px;
  }
`;

export default ContactForm;
