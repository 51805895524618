import React, { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import Post from "./Post";
import _ from "lodash";
import { FIRESTORE_FEED_POSTS_TABLE } from "../constants/FirebaseConstants";
import { useFirestore } from "react-redux-firebase";

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Feed = ({ user }) => {
  const feedPosts = useSelector(
    (state) => state.firestoreReducer.ordered.feedPosts,
  );
  const firestore = useFirestore();
  const [commentsExpanded, setCommentsExpanded] = useState(null);
  const [comment, setComment] = useState("");

  const handleCommentsButton = (index) => {
    if (commentsExpanded !== index) {
      setComment("");
      setCommentsExpanded(index);
    } else setCommentsExpanded(null);
  };

  const handlePinPost = (post) => {
    if (post.pin) {
      handleRemovePin(post);
    } else {
      // Unpin currently pinned post
      const pinnedPost = _.find(feedPosts, (post) => !!post.pin);
      if (!!pinnedPost) handleRemovePin(pinnedPost);
      firestore
        .collection(FIRESTORE_FEED_POSTS_TABLE)
        .doc(post.id)
        .update({ pin: true })
        .catch((err) => console.log(err));
    }
  };

  const handleRemovePin = (post) => {
    firestore
      .collection(FIRESTORE_FEED_POSTS_TABLE)
      .doc(post.id)
      .update({ pin: false })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {!!feedPosts &&
        _.sortBy(feedPosts, (item) => !item.pin).map((post) => (
          <Post
            key={post.id}
            index={post.id}
            post={post}
            user={user}
            handleButton={handleCommentsButton}
            expanded={commentsExpanded}
            comment={comment}
            setComment={setComment}
            handlePin={handlePinPost}
          />
        ))}
    </>
  );
};

export default Feed;
