import React from "react";
import styled from "styled-components";
import Logo from "../Logo";
import {
  SOCIAL_MEDIA_INSTAGRAM_URL,
  SOCIAL_MEDIA_LINKEDIN_URL,
} from "../../constants/CompanyConstants";
import { device } from "../../utils/breakpoints";
import DigitalKissLogo from "../DigitalKissLogo";

const HomeNavbar = () => {
  return (
    <Wrapper>
      <SocialIcon href={SOCIAL_MEDIA_INSTAGRAM_URL} target={"_blank"}>
        <i className="feather-instagram" />
      </SocialIcon>
      <LogoContainer>
        <Logo footer />
        <DigitalKissLogo />
      </LogoContainer>
      <SocialIcon href={SOCIAL_MEDIA_LINKEDIN_URL} target={"_blank"}>
        <i className="feather-linkedin" />
      </SocialIcon>
    </Wrapper>
  );
};

const SocialIcon = styled.a`
  cursor: pointer;
  z-index: 10;
  padding-bottom: 1rem;

  i {
    font-size: 28px;
    color: #fff;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 25%);

    @media ${device.lg} {
      font-size: 34px;
    }
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  padding: 0.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export default HomeNavbar;
