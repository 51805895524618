import i18n from "i18next";
import {
  SPACE_ID_FLEX_WORKSPACE,
  SPACE_ID_MEETING_ROOM,
  SPACE_ID_STUDIO,
} from "../constants/SpacesConstants";
import {
  HIRE_REQUEST_STATUS_ACCEPTED,
  HIRE_REQUEST_STATUS_COMPLETED,
  HIRE_REQUEST_STATUS_DETAILS_SUBMITTED,
  HIRE_REQUEST_STATUS_OPEN,
  HIRE_REQUEST_STATUS_REJECTED,
  HIRE_REQUEST_STATUS_SIGNED,
} from "../constants/HireRequestConstants";

export const formatSpace = (space) => {
  switch (space) {
    case SPACE_ID_STUDIO:
      return i18n.t("studio_space");
    case SPACE_ID_FLEX_WORKSPACE:
      return i18n.t("flex_space");
    case SPACE_ID_MEETING_ROOM:
      return i18n.t("meeting_room");
    default:
      return i18n.t("studio_space");
  }
};

export const formatRequestStatus = (status) => {
  switch (status) {
    case HIRE_REQUEST_STATUS_OPEN:
      return i18n.t("open");
    case HIRE_REQUEST_STATUS_ACCEPTED:
      return i18n.t("accepted");
    case HIRE_REQUEST_STATUS_DETAILS_SUBMITTED:
      return i18n.t("details_submitted");
    case HIRE_REQUEST_STATUS_SIGNED:
      return i18n.t("signed");
    case HIRE_REQUEST_STATUS_COMPLETED:
      return i18n.t("completed");
    case HIRE_REQUEST_STATUS_REJECTED:
      return i18n.t("rejected");
    default:
      return i18n.t("open");
  }
};

export const getRequestStatusColor = (status) => {
  switch (status) {
    case HIRE_REQUEST_STATUS_OPEN:
      return "orange";
    case HIRE_REQUEST_STATUS_ACCEPTED:
      return "blue";
    case HIRE_REQUEST_STATUS_SIGNED:
      return "lime";
    case HIRE_REQUEST_STATUS_COMPLETED:
      return "green";
    case HIRE_REQUEST_STATUS_REJECTED:
      return "red";
    default:
      return "orange";
  }
};
