import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { about, contact, dashboardHome, home, podcastBackstage } from "../urls";
import { Link, NavLink } from "react-router-dom";
import { colors } from "../utils/theme";
import { toggleHireSpaceModal } from "../redux/actions/modalActions";
import StyledButton from "./StyledButton";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";

const MobileMenuHome = ({ active, handleMenu }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Wrapper className={active ? "active" : ""}>
      <CloseButton onClick={handleMenu}>
        <CloseOutlined />
      </CloseButton>
      <Content>
        {/* mobile nav menu */}
        <StyledNav>
          <ul>
            <li>
              <MenuItem to={home} activeClassName="active" onClick={handleMenu}>
                {t("oostwest")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={about}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("community")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={podcastBackstage}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("podcast")}
              </MenuItem>
            </li>
            <li>
              <MenuItem
                to={contact}
                activeClassName="active"
                onClick={handleMenu}
              >
                {t("contact")}
              </MenuItem>
            </li>
          </ul>
          <Divider />
          <MenuItem
            to={dashboardHome}
            activeClassName="active"
            onClick={handleMenu}
          >
            {t("login")}
          </MenuItem>
          <RentSpaceButton
            onClick={() => {
              dispatch(toggleHireSpaceModal());
              handleMenu();
            }}
          >
            {t("rent_a_space")}
          </RentSpaceButton>
        </StyledNav>
        {/* mobile widgets */}
        <StyleElement>
          <img
            src={"assets/images/illustrations/illustratie-07.svg"}
            alt={""}
          />
        </StyleElement>
      </Content>
    </Wrapper>
  );
};

const CloseButton = styled.div`
  width: 100%;
  padding-right: 0.5rem;
  padding-top: 0.5rem;

  .anticon {
    float: right;
    font-size: 1.75rem;
    color: #888;
  }
`;

const RentSpaceButton = styled(StyledButton)`
  margin-top: 1rem;
`;

const MenuItem = styled(NavLink)`
  display: block;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.5rem 0;
  color: ${colors.menu_items};
`;

const StyledNav = styled.nav`
  padding: 0 1rem 1rem 2rem;

  li a {
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: ${colors.secondary};
    }
  }
`;

const StyleElement = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;

  img {
    max-width: 50vw;
    opacity: 0.6;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;
`;

const Wrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(245, 237, 224, 1) 20%,
    rgba(98, 144, 111, 1) 100%
  );
  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  transform: translate(-100%, 0);
  transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1);

  &.active {
    transform: translate(0, 0);
  }
`;

export default MobileMenuHome;
