import React from "react";
import styled from "styled-components";

const TextContainer = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  text-align: center;

  h4 {
    font-size: 20px;
  }

  p {
    white-space: pre-line;
  }

  a {
    font-size: 18px;
    color: #0098c2;
    text-decoration: none;
  }
`;

export default TextContainer;
