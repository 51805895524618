import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Header from "../components/Header";
import PopupChat from "../components/PopupChat";
import AppFooter from "../components/AppFooter";
import { AppWrapper } from "../styles/dashboardStyles";

const DashboardLayout = ({ children, ...rest }) => {
  // const navigate = useNavigate();
  // const { pathname } = useLocation();

  // useEffect(() => {
  //   if (pathname !== adminOverview) navigate(adminOverview);
  // }, [pathname]);

  return (
    <ParallaxProvider>
      <AppWrapper>
        <Header />
        {/*<LeftNav />*/}
        {/*<RightChat />*/}
        <main {...rest}>{children}</main>
        {/*<PopupChat />*/}
        <AppFooter />
      </AppWrapper>
    </ParallaxProvider>
  );
};

export default DashboardLayout;
