import React from "react";
import { Link } from "react-router-dom";
import {
  adminOverview,
  agenda,
  dashboardHome,
  events,
  settings,
  usersOverview,
} from "../urls";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { colors } from "../utils/theme";
import { device } from "../utils/breakpoints";

const AppFooter = () => {
  const profile = useSelector((state) => state.firebaseReducer.profile);
  const isAdmin = !!profile && profile.role === "admin";

  return (
    <StyledFooter>
      <Link to={dashboardHome}>
        <i className="feather-home" />
      </Link>
      <Link to={agenda}>
        <i className="feather-calendar" />
      </Link>
      <Link to={events}>
        <i className="feather-star" />
      </Link>
      <Link to={usersOverview}>
        <i className="feather-users" />
      </Link>
      {isAdmin && (
        <Link to={adminOverview}>
          <i className="feather-user-plus" />
        </Link>
      )}
      <Link to={settings}>
        <Avatar
          src={
            !!profile.avatar
              ? profile.avatar
              : "https://via.placeholder.com/300x300.png"
          }
          alt="user"
        />
      </Link>
    </StyledFooter>
  );
};

const StyledFooter = styled.nav`
  min-height: 3.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  z-index: 99;
  background-color: ${colors.bg_green};

  a:not(:last-child) {
    color: unset;
  }

  i {
    color: white;
    font-size: 1.5rem;
  }

  @media ${device.lg} {
    display: none;
  }
`;

const Avatar = styled.img`
  width: 32px;
  border-radius: 100%;
`;

export default AppFooter;
